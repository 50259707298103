import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import UploadBox from '../UploadBox';
import {
  ACCORDION_STATUS,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
} from '../../constants';

const InitialUploader = ({
  getPresignedUploadUrl,
  entityId,
  setAccordionStatus,
  validateUpload,
  isValidateError,
  resetValidate,
}) => {
  const [isFilePreparing, setIsFilePreparing] = useState(false);
  const getUploadUrl = async (file) => {
    const params = {
      contentType: FILE_CONTENT_TYPES.GLB_GLTF,
      fileName: file,
      entityId,
    };
    const result = await getPresignedUploadUrl(params);
    return result.data;
  };

  const getUploadParams = async (params) => {
    const { file, meta } = params;
    const uploadUrl = await getUploadUrl(meta.name);
    return {
      body: file,
      url: uploadUrl,
      meta,
      method: 'PUT',
      headers: {
        'Content-Type': FILE_CONTENT_TYPES.GLB_GLTF,
      },
    };
  };

  const handleChangeStatus = (fileDetails, status) => {
    if (
      status === FILE_UPLOAD_STATUSES.PREPARING ||
      status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
      status === FILE_UPLOAD_STATUSES.UPLOADING
    ) {
      setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
      setIsFilePreparing(true);
    }
    if (status === FILE_UPLOAD_STATUSES.DONE) {
      validateUpload(fileDetails.meta.name);
    }
  };
  const handleReupload = (fileWithMeta) => {
    fileWithMeta.remove();
    resetValidate();
    setIsFilePreparing(false);
  };
  return (
    <Box>
      <UploadBox
        getUploadParams={getUploadParams}
        handleChangeStatus={handleChangeStatus}
        isFilePreparing={isFilePreparing}
        isValidateError={isValidateError}
        handleReupload={handleReupload}
      />
    </Box>
  );
};

InitialUploader.propTypes = {
  getPresignedUploadUrl: PropTypes.func,
  entityId: PropTypes.number,
  setAccordionStatus: PropTypes.func,
  validateUpload: PropTypes.func,
  isValidateError: PropTypes.bool,
  resetValidate: PropTypes.func,
};

export default InitialUploader;
