import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import {
  DIGITAL_TWIN_ACCORDIONS,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import {
  BackdropContainer,
  ConfigTitle,
  CustomBackdrop,
  SubConfigurationsWrapperBox,
} from '../../utils/styles';
import DisplaySettings from '../DisplaySettings';
import ModelUpload from '../ModelUpload';
import TagConfig from '../TagConfig';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { useToastContext } from '../../context/toastContext';
import {
  getDigitalTwinConfig,
  getDigitalTwinTagConfig,
  getTagConfigSettings,
} from '../../utils/apiHelper';
import {
  resetMasterSlice,
  setIsDigitalTwinEnabled,
  setIsModelUploaded,
} from '../../store/slices/masterSlice';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  resetTagConfigSlice,
  setIsTagConfigEnabled,
  setNumberOfConfiguredTags,
  setNumberOfTags,
  setTagConfigDetails,
  setTagSettingsMeta,
} from '../../store/slices/tagConfigSlice';

const initialSelectedAccordion = [
  { type: DIGITAL_TWIN_ACCORDIONS.MODEL_UPLOAD, expanded: true },
  { type: DIGITAL_TWIN_ACCORDIONS.TAG_CONFIG, expanded: false },
  { type: DIGITAL_TWIN_ACCORDIONS.DISPLAY_SETTINGS, expanded: false },
];

const DigitalTwinConfig = () => {
  const { rxjsState } = useRxjsState();
  const [selectedAccordion, setSelectedAccordion] = useState(
    initialSelectedAccordion
  );
  const [showScene, setShowScene] = useState(false);
  const { toastDispatch } = useToastContext();
  const dispatch = useDispatch();
  const onExpandUpdate = (expandedAccordion) => {
    setSelectedAccordion((prevAccordion) =>
      prevAccordion.map((item) => ({
        ...item,
        expanded: item.type === expandedAccordion,
      }))
    );
  };
  const accordionIsExpanded = (accordion) => {
    return selectedAccordion.find((item) => item.type === accordion).expanded;
  };

  /* 
    Fetching Digital Twin Config details
  */
  const {
    refetch: refetchDigitalTwinConfig,
    isLoading: isDigitalTwinConfigLoading,
  } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_DT_CONFIG],
    queryFn: () => getDigitalTwinConfig(rxjsState.plantId),
    onSuccess: (res) => {
      setShowScene(res.isModelUploaded);
      dispatch(setIsDigitalTwinEnabled(res.isDigitalTwinEnabled));
      dispatch(setIsModelUploaded(res.isModelUploaded));
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId),
  });

  /* 
    Fetching Tag Config settings
  */
  const { isLoading: isTagConfigSettingsLoading } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_TAG_CONFIG_SETTINGS],
    queryFn: () => getTagConfigSettings(rxjsState.plantId),
    onSuccess: (res) => {
      dispatch(setTagSettingsMeta(res));
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId),
  });

  /* 
    Fetching Tag Config details
  */
  const {
    refetch: refetchTagConfigDetails,
    isLoading: isTagConfigDetailsLoading,
  } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_TAG_CONFIG_DETAILS],
    queryFn: () => getDigitalTwinTagConfig(rxjsState.plantId),
    onSuccess: (res) => {
      dispatch(setTagConfigDetails(res));
      dispatch(setIsTagConfigEnabled(res?.tags?.length > 0));
      dispatch(setNumberOfTags(res?.totalTags));
      dispatch(setNumberOfConfiguredTags(res?.totalConfiguredTags));
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId),
  });

  useEffect(() => {
    return () => {
      dispatch(resetMasterSlice());
      dispatch(resetTagConfigSlice());
    };
  }, [dispatch]);

  const showBackdropLoader =
    isDigitalTwinConfigLoading ||
    isTagConfigSettingsLoading ||
    isTagConfigDetailsLoading;

  return (
    <Box>
      <ConfigTitle>{MESSAGE_STRINGS['Config.page.Title']}</ConfigTitle>
      <SubConfigurationsWrapperBox>
        <Box>
          <ModelUpload
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(
              DIGITAL_TWIN_ACCORDIONS.MODEL_UPLOAD
            )}
            showScene={showScene}
            setShowScene={setShowScene}
            refetchTagConfigDetails={refetchTagConfigDetails}
          />
        </Box>
        <Box>
          <TagConfig
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(DIGITAL_TWIN_ACCORDIONS.TAG_CONFIG)}
            refetchTagConfigDetails={refetchTagConfigDetails}
          />
        </Box>
        <Box>
          <DisplaySettings
            onExpandUpdate={onExpandUpdate}
            isExpanded={accordionIsExpanded(
              DIGITAL_TWIN_ACCORDIONS.DISPLAY_SETTINGS
            )}
            refetchDigitalTwinConfig={refetchDigitalTwinConfig}
          />
        </Box>
      </SubConfigurationsWrapperBox>
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </Box>
  );
};

export default DigitalTwinConfig;
