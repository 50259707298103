import PropTypes from 'prop-types';
import { TEST_IDS } from '../../constants';
import { StatusTypography, StyledAccordionStatusBox } from '../../utils/styles';

const AccordionStatus = ({ status, type, isMarginRight = false }) => {
  return (
    <StyledAccordionStatusBox
      data-testid={TEST_IDS.ACCORDION_STATUS_VALUE}
      isMarginRight={isMarginRight}
    >
      <StatusTypography data-testid={`${TEST_IDS.ACCORDION_STATUS}-${type}`}>
        {status}
      </StatusTypography>
    </StyledAccordionStatusBox>
  );
};
AccordionStatus.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
  isMarginRight: PropTypes.bool,
};
export default AccordionStatus;
