import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  ButtonBase,
  LinearProgress,
  ListItemButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
  alpha,
  styled,
  tooltipClasses,
} from '@mui/material';

export const typographyStyles = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1rem',
};

const ConfigContainer = styled(Box)(({ theme }) => ({
  margin: '1.25rem 1.25rem 0rem 1.25rem',
  border: `0.313em solid ${theme.palette.background.layoutBackgroundColor}`,
  borderRadius: '4px 4px 0px 0px',
  height: '100%',
}));

const SubConfigurationsWrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.layoutBackgroundColor,
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  margin: '1.25rem 1.25rem 0rem 1.25rem',
  border: `0.313em solid ${theme.palette.background.layoutBackgroundColor}`,
  borderRadius: '0.25rem 0.25rem 0px 0px',
  height: '100%',
}));

const ConfigTitle = styled(Typography)({
  paddingTop: '0.813rem',
  paddingLeft: '1.313rem',
  fontWeight: 400,
  fontSize: '1.5rem',
  lineHeight: '2.043rem',
});

const SubConfigTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: '1.192rem',
});

const SubConfigContentTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  placeSelf: 'center',
  boxShadow: 'none',
  margin: '0 0 0.313rem 0',
  background: theme.palette.background.blackGrey,
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  '&:last-child': {
    margin: '0rem',
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0 0 0.313rem 0',
    '&:last-child': {
      margin: '0rem',
    },
  },
}));

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isAlert',
})(({ theme, isAlert }) => ({
  minHeight: '3.5rem !important',
  backgroundColor: theme.palette.background.darkishBlackBlue,
  borderBottom: `1px solid ${alpha(theme.customColors.black, 0.125)}`,
  paddingLeft: '1rem',
  paddingRight: '1.5rem',
  borderRadius: '4px',
  height: isAlert ? '' : '3.5rem',
  '&$expanded': {
    minHeight: '3.5rem',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1rem',
    marginTop: '1.156rem !important',
    marginBottom: '1.156rem !important',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '1.156rem 1rem 1.156rem 0px !important',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 1,
    '& .MuiAccordionSummary-expandIcon': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlue,
  padding: '0',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '&.MuiSwitch-root': {
    width: '2.25rem',
    height: '1.125rem',
    padding: 0,
    display: 'flex',
  },
  '& .MuiSwitch-switchBase': {
    padding: '0rem',
    top: '1px',
    bottom: '1px',
    left: '1px',
    '&.Mui-checked': {
      transform: 'translateX(1.125rem)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.background.saveGreen,
      },
    },
    '&.Mui-disabled ': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        background: theme.palette.background.shuttleGray,
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: '1rem',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '7.5rem',
  height: '2.25rem',
  borderRadius: '1.25rem',
  margin: 'auto 1rem auto auto',
  backgroundColor: theme.palette.background.saveGreen,
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: 600,
  '&:hover': {
    color: theme.palette.text.primary,
    background: 'inherit',
    border: `0.0625rem solid ${theme.palette.border.saveGreen}`,
  },
  '&:disabled': {
    color: theme.palette.background.twentySevenJungleGrey,
    background: theme.palette.background.darkGrey,
    border: `0.0625rem solid ${theme.palette.border.wrapperGrey}`,
  },
}));

const ButtonTypography = styled(Typography)({
  color: 'inherit',
  fontSize: '0.875rem',
  lineHeight: '1rem',
  fontWeight: 600,
  textTransform: 'none',
});

const SubConfigFooter = styled(Box)(({ theme }) => ({
  height: '3.5rem',
  backgroundColor: theme.palette.background.blackGrey,
  display: 'flex',
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
}));

const SubConfigContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '3rem',
  alignItems: 'center',
  backgroundColor: theme.palette.background.blackGrey,
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
}));

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const BackdropContainer = styled(Box)(() => ({
  display: 'flex',
  maxHeight: '100%',
  flex: 1,
  flexDirection: 'column',
}));

const DangerText = styled(Box)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
}));

const StyledToastTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.192rem',
  color: theme.customColors.white,
}));

const StyledAccordionStatusBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMarginRight',
})(({ theme, isMarginRight }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.gunmetalGrey,
  justifyContent: 'center',
  borderRadius: '0.25rem',
  minWidth: '5.8rem',
  marginLeft: 'auto',
  marginRight: isMarginRight ? '0.625rem' : '0rem',
}));

const StatusTypography = styled(Typography)({
  fontWeight: '400',
  fontSize: '0.875rem',
  lineHeight: '1.188rem',
  fontStyle: 'normal',
});

const AccordionTitleWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.text.lightYellowishGray,
    backgroundColor: theme.palette.background.eerieBlack,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    border: `1px solid ${theme.customColors.metalGrey}`,
    bordeRadius: '0.25rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: theme.palette.background.eerieBlack,
    },
  },
}));

const UploadBoxContainer = styled(Box)(({ theme }) => ({
  width: '49.375rem',
  height: '17.688rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px dashed ${theme.customColors.saveGreen}`,
}));

const ProgressContainer = styled(Box)(({ theme }) => ({
  width: '29.688rem',
  backgroundColor: theme.palette.background.elevationGrey,
  padding: '0.75rem',
  boxShadow: '0 0.25em 0.25em rgba(0, 0, 0, 0.25)',
  borderRadius: '0.25rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '0.438rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.customColors.progressBarEmpty,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.customColors.progressBarBlue,
  },
}));

const FileSizeTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  color: theme.palette.text.blockGrey,
}));

const UploadModelAccordionContent = styled(Box)({
  height: '32.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FlexBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRow',
})(({ isRow }) => ({
  display: 'flex',
  flexDirection: isRow ? 'row' : 'column',
}));

const ProgressBarWrapper = styled(Box)({
  flexGrow: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const ReuploadOnErrorContainer = styled(Box)({
  flexGrow: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1.5rem',
});

const SmallUploadIconContainer = styled(Box)(() => ({
  height: '3.125rem',
  width: '3.125rem',
}));

const ReuploadOnErrorTypography = styled(Typography)(() => ({
  fontSize: '1rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '1.362rem',
  marginTop: '0.625rem',
}));

const UploadBoxTypography = styled(Typography)({
  ...typographyStyles,
  lineHeight: '1.362rem',
  fontSize: '1rem',
});
const CustomUploadBoxButtonBase = styled(ButtonBase)({
  padding: '0rem 6rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const HeaderBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.hierarchyTableGrey}`,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '3.5rem',
}));

const UserGuideWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '0.75rem',
});

const ReuploadWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginRight: '1rem',
  width: '12.5rem',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ToolTipIconBox = styled(Box)({
  marginTop: '0.3rem',
});

const UserGuideTypography = styled(Typography)({
  ...typographyStyles,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  marginLeft: '0.5rem',
});

const ReuploadTypography = styled(Typography)({
  ...typographyStyles,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  marginLeft: '0.635rem',
  width: '9rem',
});

const TagListContainer = styled(Box)({
  height: '41.125rem',
  width: '28%',
});

const ListPanelContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.blackGrey,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ListPanelHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlackBlue,
  color: theme.palette.text.lightUnitGrey,
  minHeight: '3.5rem',
  border: `1px solid ${theme.palette.border.darkGrey}`,
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 0rem 0.25rem 0.625rem',
  borderWidth: '0px 0px 0px 0px',
  '& .MuiTypography-subtitle2': {
    lineHeight: '1.188rem',
  },
}));

const ListPanelHeaderTitle = styled(Typography)(() => ({
  marginLeft: '0.625em',
}));

const ListPanelListContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.selected.darkGrayishBlue}`,
  borderWidth: '1px 1px 0px 1px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
  overflow: 'scroll',
  scrollbarWidth: 'none', // Hide scrollbar for Mozilla firefox
  '&::-webkit-scrollbar': {
    display: 'none', // Hide scrollbar for WebKit browsers: Chrome, Edge, Safari
  },
  '&-ms-overflow-style:': {
    display: 'none', // Hide scrollbar for Internet Explorer
  },
  '& .MuiTypography-body1': {
    lineHeight: '1.188rem',
  },
  '& .MuiTypography-subtitle2': {
    lineHeight: '1.188rem',
  },
}));

const ListItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '5rem',
  borderTop: `1px solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  '&:last-child': {
    borderBottom: `1px solid ${alpha(theme.palette.border.darkGrey, 0.3)}`,
  },
}));

const SelectedMarker = styled(Box)(({ theme }) => ({
  height: '4.85rem',
  position: 'absolute',
  width: '0.5rem',
  backgroundColor: theme.palette.border.saveGreen,
  transform: 'translateY(0.1rem)',
  zIndex: 1,
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  height: '100%',
  '&:hover': {
    backgroundColor: `${theme.palette.background.gunmetalGrey}`,
  },
}));

const StyledListItemTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ isSelected }) => ({
  ...typographyStyles,
  fontSize: isSelected ? '1rem' : '0.875rem',
  fontWeight: isSelected ? 700 : 400,
  lineHeight: isSelected ? '1rem' : '1.192rem',
}));

const StyledListItemNameTypography = styled(Typography)(({ theme }) => ({
  ...typographyStyles,
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.021rem !important',
  marginTop: '0.625rem',
  color: theme.customColors.white,
}));

const TagSourceAndDetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '72%',
  padding: '2rem',
});

const TagNameAndSourceContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const TagNameWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const TagNameTypography = styled(Typography)({
  ...typographyStyles,
  fontSize: '1.125rem',
  fontWeight: 700,
  marginRight: '0.5rem',
});

const DividerContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginTop: '0.75rem',
}));

const DividerLine = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.border.wrapperGrey,
  height: '0.065rem',
}));

const CardDetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1.25rem',
});

const CardDetailsTypography = styled(Typography)({
  ...typographyStyles,
  fontSize: '1rem',
  fontWeight: 700,
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.layoutBackgroundColor,
  '.Mui-focused': {
    border: `0.0625rem solid ${theme.palette.border.saveGreen}`,
  },
  '& .MuiOutlinedInput-root': {
    height: '2rem',
    alignContent: 'center',
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '.MuiAutocomplete-endAdornment': {
    right: '1rem !important',
  },
  ':active': {
    border: `1px solid ${theme.palette.border.lightWhiteGrey}`,
  },
  '& .Mui-disabled': {
    border: `1px solid ${alpha(theme.palette.border.lightWhiteGrey, 0.2)}`,
    input: {
      border: 'none',
      color: `${theme.palette.text.darkGrey}`,
      '-webkit-text-fill-color': 'unset',
    },
    button: {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    ...typographyStyles,
    fontSize: '0.875rem',
    lineHeight: '1.192rem',
    padding: '0rem',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: `${theme.palette.selected.darkGrayishBlue}`,
      '&.Mui-focused': {
        backgroundColor: `${theme.palette.selected.darkGrayishBlue}`,
      },
    },
  },
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: `${theme.palette.selected.darkGrayishBlue}`,
  },
  fontSize: '0.875rem',
  lineHeight: '1.192rem',
  fontWeight: 400,
  backgroundColor: theme.palette.background.paper,
}));

const DropdownLabelWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSmall',
})(({ isSmall }) => ({
  width: isSmall ? '10.93rem' : '17rem',
  marginTop: '1.5rem',
}));

const DropdownLabelAndTooltipWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const DropdownLabelTypography = styled(Typography)({
  ...typographyStyles,
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.192rem',
  marginRight: '0.5rem',
});

const ParameterTagLevelLabelContainer = styled(Box)({
  width: '38.2rem',
  marginLeft: '2rem',
});

const ParameterTagLevelContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  margin: '0rem 3rem',
  '& > *': {
    marginRight: '1rem',
    '&:last-child': {
      margin: '0rem',
    },
  },
});

const MetricsDropdownWrapper = styled(Box)({
  width: '10.93rem',
});

const DeleteMetricIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'metricsLength',
})(({ metricsLength }) => ({
  padding: '0.2rem 0rem 0rem 1rem',
  cursor: metricsLength > 1 ? 'pointer' : 'default',
}));

const AddMetricIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'metricsLength',
})(({ metricsLength }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '1rem',
  cursor: metricsLength < 4 ? 'pointer' : 'default',
}));

export {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  ConfigContainer,
  StyledSwitch,
  ConfigTitle,
  SubConfigTitle,
  SubConfigContentTypography,
  StyledButton,
  SubConfigFooter,
  ButtonTypography,
  SubConfigContentWrapper,
  CustomBackdrop,
  BackdropContainer,
  DangerText,
  StyledBackdrop,
  StyledToastTypography,
  SubConfigurationsWrapperBox,
  StyledAccordionStatusBox,
  StatusTypography,
  AccordionTitleWrapper,
  StyledTooltip,
  UploadBoxContainer,
  ProgressContainer,
  ReuploadOnErrorContainer,
  SmallUploadIconContainer,
  ReuploadOnErrorTypography,
  FileSizeTypography,
  StyledLinearProgress,
  UploadModelAccordionContent,
  FlexBox,
  ProgressBarWrapper,
  UploadBoxTypography,
  CustomUploadBoxButtonBase,
  HeaderBox,
  UserGuideWrapper,
  ReuploadWrapper,
  ToolTipIconBox,
  UserGuideTypography,
  ReuploadTypography,
  TagListContainer,
  ListPanelContainer,
  ListPanelHeader,
  ListPanelHeaderTitle,
  ListPanelListContainer,
  ListItemWrapper,
  SelectedMarker,
  StyledListItem,
  StyledListItemTypography,
  TagSourceAndDetailsContainer,
  TagNameAndSourceContainer,
  TagNameWrapper,
  TagNameTypography,
  CardDetailsContainer,
  CardDetailsTypography,
  StyledAutocomplete,
  StyledPaper,
  DropdownLabelWrapper,
  DropdownLabelAndTooltipWrapper,
  DropdownLabelTypography,
  DividerContainer,
  DividerLine,
  ParameterTagLevelLabelContainer,
  ParameterTagLevelContainer,
  MetricsDropdownWrapper,
  DeleteMetricIconContainer,
  AddMetricIconContainer,
  StyledListItemNameTypography,
};
