import PropTypes from 'prop-types';
import { ListPanelContainer, ListPanelListContainer } from '../../utils/styles';

function ListPanel({ children }) {
  return (
    <ListPanelContainer>
      <ListPanelListContainer>{children}</ListPanelListContainer>
    </ListPanelContainer>
  );
}

ListPanel.propTypes = {
  children: PropTypes.node,
};

export default ListPanel;
