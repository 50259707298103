import { forwardRef, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DigiTwin } from '@smf/ui-digi-twin-app';
import { setIsModelUploaded } from '../../store/slices/masterSlice';
import {
  ACCORDION_STATUS,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
  TEST_IDS,
} from '../../constants';
import UploadBox from '../UploadBox';
import { useRxjsState } from '../../utils/hooks/useRxjsState';

const ReuploaderAndScene = forwardRef((props, ref) => {
  const {
    entityId,
    getPresignedUploadUrl,
    setAccordionStatus,
    validateUpload,
    setFileWithMeta,
    isModelUploaded,
    isValidateError,
    resetValidate,
    setIsSaveButtonDisabled,
    setIsSceneLoading,
    setSceneMetaData,
  } = props;
  const { rxjsState } = useRxjsState();

  const [isFilePreparing, setIsFilePreparing] = useState(false);
  const dispatch = useDispatch();
  const getUploadUrl = async (file) => {
    const params = {
      contentType: FILE_CONTENT_TYPES.GLB_GLTF,
      fileName: file,
      entityId,
    };
    const result = await getPresignedUploadUrl(params);
    return result.data;
  };

  const getUploadParams = async (params) => {
    const { file, meta } = params;
    const uploadUrl = await getUploadUrl(meta.name);
    return {
      body: file,
      url: uploadUrl,
      meta,
      method: 'PUT',
      headers: {
        'Content-Type': FILE_CONTENT_TYPES.GLB_GLTF,
      },
    };
  };

  const handleChangeStatus = (fileDetails, status) => {
    if (status === FILE_UPLOAD_STATUSES.DONE) {
      setFileWithMeta(fileDetails);
      validateUpload(fileDetails.meta.name);
    }
    if (
      status === FILE_UPLOAD_STATUSES.PREPARING ||
      status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
      status === FILE_UPLOAD_STATUSES.UPLOADING
    ) {
      setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
      setIsFilePreparing(true);
      dispatch(setIsModelUploaded(false));
    }
  };
  const handleReupload = (fileWithMeta) => {
    fileWithMeta.remove();
    ref.current?.dropzone?.current?.firstChild?.lastChild?.click();
    resetValidate();
    setIsFilePreparing(false);
  };
  const handleSceneChange = (snap) => {
    if (snap.document.componentNodeMap['Tag'] !== undefined) {
      setIsSaveButtonDisabled(false);
      setSceneMetaData(snap);
    }
  };
  return (
    <>
      {isModelUploaded && (
        <Box
          sx={{ width: '78vw', height: '32.25rem' }}
          data-testid={TEST_IDS.SCENE_CONTAINER}
        >
          {rxjsState?.idToken && (
            <DigiTwin
              enableEditMode={true}
              handleSceneChange={handleSceneChange}
              setIsSceneLoading={setIsSceneLoading}
            />
          )}
        </Box>
      )}
      <Box
        sx={{ display: isModelUploaded ? 'none' : '' }}
        data-testId={TEST_IDS.SECONDARY_UPLOAD_BOX}
      >
        <UploadBox
          getUploadParams={getUploadParams}
          handleChangeStatus={handleChangeStatus}
          isFilePreparing={isFilePreparing}
          isValidateError={isValidateError}
          handleReupload={handleReupload}
          ref={ref}
        />
      </Box>
    </>
  );
});

ReuploaderAndScene.displayName = ReuploaderAndScene;

ReuploaderAndScene.propTypes = {
  getPresignedUploadUrl: PropTypes.func,
  entityId: PropTypes.number,
  setAccordionStatus: PropTypes.func,
  validateUpload: PropTypes.func,
  setFileWithMeta: PropTypes.func,
  isModelUploaded: PropTypes.bool,
  isValidateError: PropTypes.bool,
  resetValidate: PropTypes.func,
  setSceneMetaData: PropTypes.func,
  setIsSceneLoading: PropTypes.func,
  setIsSaveButtonDisabled: PropTypes.bool,
};

export default ReuploaderAndScene;
