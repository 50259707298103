import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { digitalTwinModelDataSelector } from '../../store/storeSelectors';
import { setIsModelUploaded } from '../../store/slices/masterSlice';
import Accordion from '../../components/Accordion';
import InitialUploder from '../../components/InitialUploader';
import {
  ACCORDION_IDS,
  ACCORDION_STATUS,
  DIGITAL_TWIN_ACCORDIONS,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import AccordionDownArrow from '../../assets/downArrow.svg';
import InfoIcon from '../../assets/infoIcon.svg';
import {
  AccordionTitleWrapper,
  BackdropContainer,
  ButtonTypography,
  CustomBackdrop,
  StyledButton,
  SubConfigFooter,
  SubConfigTitle,
  ToolTipIconBox,
} from '../../utils/styles';
import { BUTTON_TEXT, MESSAGE_STRINGS } from '../../constants/en-us';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import AccordionStatus from '../../components/AccordionStatus';
import CustomTooltip from '../../components/CustomTooltip';
import {
  getPresignedUploadUrl,
  saveTagDetails,
  validateFileUpload,
} from '../../utils/apiHelper';
import LoadingIndicator from '../../components/LoadingIndicator';
import UploadBoxHeader from '../../components/UploadBoxHeader';
import ReuploaderAndScene from '../../components/ReuploaderAndScene';
import { useToastContext } from '../../context/toastContext';
import { Box } from '@mui/material';

const ModelUpload = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  showScene,
  setShowScene,
  refetchTagConfigDetails,
}) => {
  const { rxjsState } = useRxjsState();
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isSceneLoading, setIsSceneLoading] = useState(false);
  const [sceneMetaData, setSceneMetaData] = useState(null);
  const [fileWithMeta, setFileWithMeta] = useState(null);
  const [isUserGuidePdfUrlFetching, setIsUserGuidePdfUrlFetching] =
    useState(false);
  const dropZoneRef = useRef(null);

  const { factoryId } = rxjsState;

  const dispatch = useDispatch();
  const { toastDispatch } = useToastContext();

  const isModelUploaded = useSelector(digitalTwinModelDataSelector);

  /* 
    Validate file upload
  */
  const {
    isLoading: isValidateTargetsProcessing,
    isError: isValidateError,
    isSuccess: isValidationSuccess,
    reset: resetValidateTargets,
    mutate: validateUpload,
  } = useMutation(
    [QUERY_CONSTANTS.VALIDATE_FILE, fileWithMeta],
    async (fileName) => {
      const params = {
        entityId: factoryId,
        fileName,
      };
      return validateFileUpload(params);
    },
    {
      onSuccess: () => {
        setAccordionStatus(ACCORDION_STATUS.COMPLETED);
        dispatch(setIsModelUploaded(true));
        setShowScene(true);
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.upload.SUCCESS'],
          },
        });
      },
      onError: (error) => {
        if (error?.response?.data?.message) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: error.response.data.message,
            },
          });
        }
      },
      retry: false,
    }
  );

  /* 
    Save tag data
  */
  const { mutate: addTagMutate, isLoading: isSaveTagDetailsProcessing } =
    useMutation(
      [QUERY_CONSTANTS.POST_TAG_DETAILS],
      async (reqBody) => {
        return saveTagDetails(reqBody);
      },
      {
        onSuccess: () => {
          refetchTagConfigDetails();
          setIsSaveButtonDisabled(true);
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message: MESSAGE_STRINGS['Toast.message.modelTagSave.SUCCESS'],
            },
          });
        },
        onError: () => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: MESSAGE_STRINGS['Toast.message.ERROR'],
            },
          });
        },
      }
    );

  const openUploadGlb = () => {
    if (dropZoneRef.current) {
      dropZoneRef.current?.dropzone?.current?.firstChild?.lastChild?.click();
    }
  };
  const removeExistingFileData = (fileWithMetaToBeRemoved) => {
    if (fileWithMetaToBeRemoved) {
      fileWithMetaToBeRemoved.remove();
    }
  };
  const handleReupload = () => {
    openUploadGlb();
  };

  const handleModelUploadSaveClick = () => {
    const reqBody = {
      factoryId,
      sceneMetaData,
    };
    addTagMutate(reqBody);
  };

  useEffect(() => {
    if (isValidationSuccess) {
      removeExistingFileData(fileWithMeta);
    }
  }, [isValidationSuccess, fileWithMeta]);

  useEffect(() => {
    if (isModelUploaded) setAccordionStatus(ACCORDION_STATUS.COMPLETED);
  }, [isModelUploaded]);

  const showBackdropLoader =
    isValidateTargetsProcessing ||
    isSceneLoading ||
    isSaveTagDetailsProcessing ||
    isUserGuidePdfUrlFetching;

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={() => {
          onExpandUpdate(DIGITAL_TWIN_ACCORDIONS.MODEL_UPLOAD);
        }}
      >
        <Accordion.AccordionSummary
          expandIcon={
            <AccordionDownArrow
              alt="down arrow"
              height="0.563rem"
              width="1rem"
            />
          }
          id={ACCORDION_IDS.MODEL_UPLOAD}
          data-testid={TEST_IDS.MODEL_UPLOAD_ACCORDION}
        >
          <AccordionTitleWrapper>
            <SubConfigTitle sx={{ marginRight: '0.625rem' }}>
              {MESSAGE_STRINGS['Config.header.modelFileUpload']}
            </SubConfigTitle>
            <CustomTooltip
              title={MESSAGE_STRINGS['Tooltip.message.modelUpload']}
              arrow
              placement="top-start"
              data-testid={TEST_IDS.MODEL_UPLOAD_ACCORDION_TOOLTIP}
            >
              <ToolTipIconBox>
                <InfoIcon
                  alt="upload-info-icon"
                  height="1rem"
                  width="1rem"
                  data-testid={TEST_IDS.MODEL_UPLOAD_ACCORDION_TOOLTIP_ICON}
                />
              </ToolTipIconBox>
            </CustomTooltip>
          </AccordionTitleWrapper>
          <AccordionStatus
            type={DIGITAL_TWIN_ACCORDIONS.MODEL_UPLOAD}
            status={accordionStatus}
          />
        </Accordion.AccordionSummary>
        <Accordion.AccordionDetails data-testid={TEST_IDS.MODEL_UPLOAD_CONTENT}>
          <Box>
            <UploadBoxHeader
              handleReupload={handleReupload}
              setIsUserGuidePdfUrlFetching={setIsUserGuidePdfUrlFetching}
            />
            {showScene ? (
              <ReuploaderAndScene
                entityId={factoryId}
                validateUpload={validateUpload}
                fileWithMeta={fileWithMeta}
                setFileWithMeta={setFileWithMeta}
                removeExistingFileData={removeExistingFileData}
                getPresignedUploadUrl={getPresignedUploadUrl}
                setAccordionStatus={setAccordionStatus}
                isModelUploaded={isModelUploaded}
                isValidateError={isValidateError}
                resetValidate={resetValidateTargets}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                setIsSceneLoading={setIsSceneLoading}
                setSceneMetaData={setSceneMetaData}
                ref={dropZoneRef}
              />
            ) : (
              <InitialUploder
                getPresignedUploadUrl={getPresignedUploadUrl}
                entityId={factoryId}
                setAccordionStatus={setAccordionStatus}
                validateUpload={validateUpload}
                isValidateError={isValidateError}
                resetValidate={resetValidateTargets}
              />
            )}
          </Box>

          <SubConfigFooter>
            <StyledButton
              disabled={isSaveButtonDisabled}
              onClick={handleModelUploadSaveClick}
              data-testid={TEST_IDS.SAVE_TAGS}
            >
              <ButtonTypography>{BUTTON_TEXT.SAVE}</ButtonTypography>
            </StyledButton>
          </SubConfigFooter>
        </Accordion.AccordionDetails>
      </Accordion>
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </>
  );
};

ModelUpload.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  showScene: PropTypes.bool,
  setShowScene: PropTypes.func,
  refetchTagConfigDetails: PropTypes.func,
};

export default ModelUpload;
