import { Box, ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import GLBIcon from '../../assets/glbFileIcon.svg';
import ExclamationMark from '../../assets/exclamationMark.svg';
import GreenUploadIcon from '../../assets/greenUploadIcon.svg';
import { formatBytes } from '../../utils/helper';
import {
  FileSizeTypography,
  FlexBox,
  ProgressBarWrapper,
  ProgressContainer,
  ReuploadOnErrorContainer,
  ReuploadOnErrorTypography,
  SmallUploadIconContainer,
  StyledLinearProgress,
} from '../../utils/styles';
import { TEST_IDS } from '../../constants';
import { MESSAGE_STRINGS } from '../../constants/en-us';

const UploadPreview = ({
  previewComponentProps,
  isValidateError,
  handleReupload,
}) => {
  const { meta: { name, size, percent } = {}, fileWithMeta } =
    previewComponentProps;
  return (
    <Box>
      <FlexBox>
        <Box>
          <ProgressContainer>
            <Box style={{ flexGrow: '0.2' }}>
              <GLBIcon width="2.708rem" height="3.25rem" alt="glb-icon" />
            </Box>
            <ProgressBarWrapper>
              <FlexBox isRow>
                <Box>{name}</Box>
              </FlexBox>
              <FileSizeTypography>{formatBytes(size)}</FileSizeTypography>
              <StyledLinearProgress
                variant="determinate"
                value={isValidateError ? 70 : Math.round(percent)}
              />
            </ProgressBarWrapper>
            {isValidateError && (
              <Box
                sx={{ flexGrow: '0.2', marginLeft: '2.125rem' }}
                data-testid={TEST_IDS.PREVIEW_ERROR}
              >
                <ExclamationMark height="2rem" width="2rem" alt="error-icon" />
              </Box>
            )}
          </ProgressContainer>
        </Box>
        {isValidateError && (
          <ReuploadOnErrorContainer>
            <ButtonBase
              data-testid={TEST_IDS.ERROR_UPLOAD_BTN}
              onClick={() => handleReupload(fileWithMeta)}
              disableRipple
            >
              <SmallUploadIconContainer>
                <GreenUploadIcon
                  alt="reupload-icon"
                  height="3.125rem"
                  width="3.125rem"
                />
              </SmallUploadIconContainer>
            </ButtonBase>
            <ReuploadOnErrorTypography>
              {MESSAGE_STRINGS['UploadModel.reupload.preview.text']}
            </ReuploadOnErrorTypography>
          </ReuploadOnErrorContainer>
        )}
      </FlexBox>
    </Box>
  );
};

UploadPreview.propTypes = {
  previewComponentProps: PropTypes.any,
  isValidateError: PropTypes.bool,
  handleReupload: PropTypes.func,
};
export default UploadPreview;
