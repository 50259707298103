import { createSlice } from '@reduxjs/toolkit';
import { SLICE_KEYS, SLICE_NAMES } from '../storeConstants';
const initialState = {
  [SLICE_KEYS.IS_MODEL_UPLOADED]: false,
  [SLICE_KEYS.IS_DT_ENABLED]: false,
  [SLICE_KEYS.IS_TAG_CONFIG_COMPLETED]: false,
};
const masterSlice = createSlice({
  name: SLICE_NAMES.MASTER_SLICE,
  initialState,
  reducers: {
    resetMasterSlice: (state) => {
      Object.assign(state, initialState);
    },
    setIsModelUploaded: (state, action) => {
      state[SLICE_KEYS.IS_MODEL_UPLOADED] = action.payload;
    },
    setIsDigitalTwinEnabled: (state, action) => {
      state[SLICE_KEYS.IS_DT_ENABLED] = action.payload;
    },
    setIsTagConfigCompleted: (state, action) => {
      state[SLICE_KEYS.IS_TAG_CONFIG_COMPLETED] = action.payload;
    },
  },
});
export const {
  setIsModelUploaded,
  setIsDigitalTwinEnabled,
  resetMasterSlice,
  setIsTagConfigCompleted,
} = masterSlice.actions;
export default masterSlice.reducer;
