import { DEFAULT_METRICS } from '../constants';

const tagDisplayDetails = (data) => {
  const tagDetailsFormated = [];
  data?.tags?.forEach((tag) => {
    tagDetailsFormated.push({
      name: tag.name,
      tagSourceId: tag.tagSourceId,
      tagSourceName: tag.tagSourceName,
      linkDestination: tag.linkDestination,
      metrics: tag.metrics?.length ? tag.metrics : [DEFAULT_METRICS],
      configStatus: tag.configStatus,
    });
  });
  return tagDetailsFormated;
};

export { tagDisplayDetails };
