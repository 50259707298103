import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { List } from '@mui/material';
import PropTypes from 'prop-types';
import useStoreData from '../../utils/hooks/useStoreData';
import Accordion from '../../components/Accordion';
import ListPanel from '../../components/ListPanel';
import PanelListItem from '../../components/PanelListItem';
import CustomTooltip from '../../components/CustomTooltip';
import NamedCombobox from '../../components/NamedCombobox';
import {
  getTagSourceListSelector,
  getLinkDestinationListSelector,
} from '../../store/storeSelectors';
import AccordionStatus from '../../components/AccordionStatus';
import {
  getSelectedTagDetails,
  isTagConfigurationValid,
} from '../../utils/helper';
import { SLICE_KEYS, SLICE_NAMES } from '../../store/storeConstants';
import {
  ACCORDION_IDS,
  ACCORDION_STATUS,
  DEFAULT_METRICS,
  DIGITAL_TWIN_ACCORDIONS,
  METRIC_NAMES,
  PLACEHOLDER,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { BUTTON_TEXT, MESSAGE_STRINGS } from '../../constants/en-us';
import AccordionDownArrow from '../../assets/downArrow.svg';
import AccordionDisableArrow from '../../assets/CaretDisableArrow.svg';
import InfoIcon from '../../assets/infoIcon.svg';
import AddIcon from '../../assets/addIcon.svg';
import AddIconDisabled from '../../assets/addIconDisabled.svg';
import {
  AccordionTitleWrapper,
  AddMetricIconContainer,
  BackdropContainer,
  ButtonTypography,
  CardDetailsContainer,
  CardDetailsTypography,
  CustomBackdrop,
  DividerContainer,
  DividerLine,
  DropdownLabelWrapper,
  FlexBox,
  ParameterTagLevelLabelContainer,
  StyledButton,
  SubConfigFooter,
  SubConfigTitle,
  TagListContainer,
  TagNameAndSourceContainer,
  TagNameTypography,
  TagNameWrapper,
  TagSourceAndDetailsContainer,
  ToolTipIconBox,
} from '../../utils/styles';
import TagMetricsRow from '../../components/TagMetricsRow';
import {
  setTagSettingsMetricsMeta,
  updateSelectedTagConfigDetails,
} from '../../store/slices/tagConfigSlice';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  getDigitalTwinTagMetricsSettings,
  updateDigitalTwinTagConfig,
} from '../../utils/apiHelper';
import { useToastContext } from '../../context/toastContext';
import { setIsTagConfigCompleted } from '../../store/slices/masterSlice';
import DropdownLabel from '../../components/DropdownLabel';

const TagConfig = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  refetchTagConfigDetails,
}) => {
  const {
    [SLICE_KEYS.TAG_CONFIG_DETAILS]: tagConfigDetails,
    [SLICE_KEYS.IS_TAG_CONFIG_ENABLED]: isTagConfigEnabled,
    [SLICE_KEYS.TAG_CONFIG_DETAILS_META]: tagConfigDetailsMeta,
    [SLICE_KEYS.TAG_SETTINGS_META]: tagSettingsMeta,
    [SLICE_KEYS.NUMBER_OF_TAGS]: numberOfTags,
    [SLICE_KEYS.NUMBER_OF_CONFIGURED_TAGS]: numberOfConfiguredTags,
  } = useStoreData(
    [SLICE_NAMES.TAG_CONFIG_SLICE],
    [
      SLICE_KEYS.TAG_CONFIG_DETAILS,
      SLICE_KEYS.IS_TAG_CONFIG_ENABLED,
      SLICE_KEYS.TAG_SETTINGS_META,
      SLICE_KEYS.TAG_CONFIG_DETAILS_META,
      SLICE_KEYS.NUMBER_OF_TAGS,
      SLICE_KEYS.NUMBER_OF_CONFIGURED_TAGS,
    ]
  );
  const { toastDispatch } = useToastContext();

  const tagSourceList = useSelector(getTagSourceListSelector);
  const linkDestinationList = useSelector(getLinkDestinationListSelector);

  const dispatch = useDispatch();

  const [selectedTagName, setSelectedTagName] = useState(null);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );

  let selectedTagDetails = JSON.parse(
    JSON.stringify(getSelectedTagDetails(selectedTagName, tagConfigDetails))
  );

  function handleOnTagSelect(name) {
    if (!selectedTagDetails.configStatus) {
      selectedTagDetails = JSON.parse(
        JSON.stringify(
          getSelectedTagDetails(selectedTagName, tagConfigDetailsMeta)
        )
      );
      if (!selectedTagDetails?.metrics?.length)
        selectedTagDetails.metrics.push(DEFAULT_METRICS);
      dispatch(updateSelectedTagConfigDetails(selectedTagDetails));
    }
    setIsSaveButtonEnabled(false);
    if (numberOfConfiguredTags) {
      setAccordionStatus(ACCORDION_STATUS.COMPLETED);
    } else {
      setAccordionStatus(ACCORDION_STATUS.NOT_STARTED);
    }
    setSelectedTagName(name);
  }

  const handleAddMetric = () => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    selectedTagDetails.metrics.push(DEFAULT_METRICS);
    selectedTagDetails.configStatus = false;
    dispatch(updateSelectedTagConfigDetails(selectedTagDetails));
    setIsSaveButtonEnabled(isTagConfigurationValid(selectedTagDetails));
  };

  const handleDeleteMetric = (index) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    selectedTagDetails.metrics.splice(index, 1);
    selectedTagDetails.configStatus = false;
    dispatch(updateSelectedTagConfigDetails(selectedTagDetails));
    setIsSaveButtonEnabled(isTagConfigurationValid(selectedTagDetails));
  };

  const handleTagConfigSave = () => {
    let reqBody = { ...selectedTagDetails };
    delete reqBody.configStatus;
    updateTagConfigDetails(reqBody);
  };

  const handleDropdownChange = (value, index, metricName) => {
    // Creating copy of selectedTagDetails
    let updatedSelectedTagDetails = JSON.parse(
      JSON.stringify(selectedTagDetails)
    );
    updatedSelectedTagDetails.configStatus = false;

    const resetMetrics = () => {
      if (selectedTagDetails?.metrics?.length === 1) {
        updatedSelectedTagDetails.metrics = [{ ...DEFAULT_METRICS }];
      } else {
        updatedSelectedTagDetails.metrics[index] = { ...DEFAULT_METRICS };
      }
    };

    switch (metricName) {
      case METRIC_NAMES.TAG_SOURCE: {
        tagSettingsMeta?.tagSourceList?.forEach((setting) => {
          if (setting.name === value) {
            updatedSelectedTagDetails.tagSourceId = setting.tagSourceId;
          }
        });
        updatedSelectedTagDetails.tagSourceName = value;
        updatedSelectedTagDetails.linkDestination = null;
        updatedSelectedTagDetails.metrics = [DEFAULT_METRICS];
        break;
      }
      case METRIC_NAMES.LINK_DESTINATION: {
        updatedSelectedTagDetails.linkDestination = value;
        updatedSelectedTagDetails.metrics = [DEFAULT_METRICS];
        break;
      }
      case METRIC_NAMES.FUNCTIONAL_AREA: {
        resetMetrics();
        updatedSelectedTagDetails.metrics[index].functionalArea = value;
        break;
      }
      case METRIC_NAMES.PARAMETER_TAG_LEVEL: {
        const functionalAreaValue =
          updatedSelectedTagDetails.metrics[index].functionalArea;
        resetMetrics();
        updatedSelectedTagDetails.metrics[index].functionalArea =
          functionalAreaValue;
        updatedSelectedTagDetails.metrics[index].parameterTagLevel = value;
        break;
      }
      case METRIC_NAMES.ASSET: {
        updatedSelectedTagDetails.metrics[index].asset = value;
        updatedSelectedTagDetails.metrics[index].subAsset = null;
        updatedSelectedTagDetails.metrics[index].metric = null;
        break;
      }
      case METRIC_NAMES.SUB_ASSET: {
        updatedSelectedTagDetails.metrics[index].subAsset = value;
        updatedSelectedTagDetails.metrics[index].metric = null;
        break;
      }
      case METRIC_NAMES.METRIC: {
        updatedSelectedTagDetails.metrics[index].metric = value;
        break;
      }
    }
    dispatch(updateSelectedTagConfigDetails(updatedSelectedTagDetails));
    setIsSaveButtonEnabled(isTagConfigurationValid(updatedSelectedTagDetails));
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
  };

  /* 
    Fetching Tag Config Metrics settings
  */
  const {
    refetch: refetchTagConfigMetricsSettings,
    isFetching: isTagConfigMetricsSettingsFetching,
  } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_TAG_CONFIG_METRICS_SETTINGS],
    queryFn: () => {
      let metricsList = [];
      selectedTagDetails?.metrics.forEach((metric) => {
        let metricObject = {
          functionalArea: metric.functionalArea,
          parameterTagLevel: [metric.parameterTagLevel],
        };
        metricsList.push(metricObject);
      });
      const reqBody = {
        cellId: selectedTagDetails?.tagSourceId,
        metricsList: metricsList,
      };
      return getDigitalTwinTagMetricsSettings(reqBody);
    },
    onSuccess: (res) => {
      dispatch(setTagSettingsMetricsMeta(res));
    },
    onError: () => {
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
        payload: {
          message: MESSAGE_STRINGS['Toast.message.ERROR'],
        },
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!selectedTagDetails && !!selectedTagDetails.configStatus,
  });

  /* 
    Update Tag Config Details
  */

  const {
    mutate: updateTagConfigDetails,
    isLoading: isUpdateTagConfigDetailsLoading,
  } = useMutation(
    [QUERY_CONSTANTS.UPDATE_TAG_CONFIG_DETAILS],
    async (reqBody) => {
      return updateDigitalTwinTagConfig(reqBody);
    },
    {
      onSuccess: () => {
        setIsSaveButtonEnabled(false);
        refetchTagConfigDetails();
        setAccordionStatus(ACCORDION_STATUS.COMPLETED);
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message:
              MESSAGE_STRINGS['Toast.message.tagConfigDetailsUpdate.SUCCESS'],
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
      },
    }
  );

  useEffect(() => {
    if (tagConfigDetailsMeta?.length) {
      setSelectedTagName(tagConfigDetailsMeta[0].name);
    } else {
      setSelectedTagName(null);
    }
  }, [tagConfigDetailsMeta[0]?.name, tagConfigDetailsMeta[0]?.metrics?.length]);

  useEffect(() => {
    setAccordionStatus(
      numberOfConfiguredTags
        ? ACCORDION_STATUS.COMPLETED
        : ACCORDION_STATUS.NOT_STARTED
    );
    dispatch(setIsTagConfigCompleted(!!numberOfConfiguredTags));
  }, [dispatch, numberOfConfiguredTags]);

  const showBackdropLoader =
    isUpdateTagConfigDetailsLoading || isTagConfigMetricsSettingsFetching;

  const getCardDetails = () => {
    return (
      <TagSourceAndDetailsContainer
        data-testid={TEST_IDS.CARD_DETAILS_CONTAINER}
      >
        <TagNameAndSourceContainer>
          <TagNameWrapper>
            <TagNameTypography>{selectedTagDetails?.name}</TagNameTypography>
            <CustomTooltip
              title={MESSAGE_STRINGS['Tooltip.message.tagConfig.tagName']}
              arrow
              placement="top-start"
            >
              <ToolTipIconBox>
                <InfoIcon alt="upload-info-icon" height="1rem" width="1rem" />
              </ToolTipIconBox>
            </CustomTooltip>
          </TagNameWrapper>
          <NamedCombobox
            label={MESSAGE_STRINGS['TagConfig.dropdownLabel.tagSource']}
            tooltipMessage={
              MESSAGE_STRINGS['Tooltip.message.tagConfig.tagSource']
            }
            placeholder={PLACEHOLDER}
            options={tagSourceList}
            onSelectionChanged={handleDropdownChange}
            value={selectedTagDetails?.tagSourceName ?? ''}
            metricName={METRIC_NAMES.TAG_SOURCE}
          />
        </TagNameAndSourceContainer>
        <DividerContainer>
          <DividerLine />
        </DividerContainer>
        <CardDetailsContainer>
          <CardDetailsTypography>
            {MESSAGE_STRINGS['TagConfig.header.cardDetails']}
          </CardDetailsTypography>
        </CardDetailsContainer>
        <NamedCombobox
          label={MESSAGE_STRINGS['TagConfig.dropdownLabel.linkDestination']}
          tooltipMessage={
            MESSAGE_STRINGS['Tooltip.message.tagConfig.linkDestination']
          }
          placeholder={PLACEHOLDER}
          options={linkDestinationList}
          isDisabled={!selectedTagDetails?.tagSourceName}
          onSelectionChanged={handleDropdownChange}
          value={selectedTagDetails?.linkDestination ?? ''}
          metricName={METRIC_NAMES.LINK_DESTINATION}
        />
        <FlexBox isRow>
          <DropdownLabelWrapper isSmall>
            <DropdownLabel
              label={MESSAGE_STRINGS['TagConfig.dropdownLabel.functionalArea']}
              tooltipMessage={
                MESSAGE_STRINGS['Tooltip.message.tagConfig.functionalArea']
              }
            />
          </DropdownLabelWrapper>
          <ParameterTagLevelLabelContainer>
            <DropdownLabelWrapper isSmall>
              <DropdownLabel
                label={
                  MESSAGE_STRINGS['TagConfig.dropdownLabel.parameterTagLevel']
                }
                tooltipMessage={
                  MESSAGE_STRINGS['Tooltip.message.tagConfig.parameterTagLevel']
                }
              />
            </DropdownLabelWrapper>
          </ParameterTagLevelLabelContainer>
          <DropdownLabelWrapper isSmall>
            <DropdownLabel
              label={MESSAGE_STRINGS['TagConfig.dropdownLabel.metric']}
              tooltipMessage={
                MESSAGE_STRINGS['Tooltip.message.tagConfig.metric']
              }
            />
          </DropdownLabelWrapper>
        </FlexBox>
        {selectedTagDetails?.metrics?.map((metricInstance, index) => (
          <TagMetricsRow
            key={`tagMetricsRow${metricInstance}`}
            index={index}
            handleDeleteMetric={handleDeleteMetric}
            metricInstance={metricInstance}
            onChange={handleDropdownChange}
            selectedTagDetails={selectedTagDetails}
            refetchTagConfigMetricsSettings={refetchTagConfigMetricsSettings}
          />
        ))}
        <AddMetricIconContainer
          metricsLength={selectedTagDetails?.metrics?.length}
        >
          {selectedTagDetails?.metrics?.length < 4 ? (
            <CustomTooltip
              title={MESSAGE_STRINGS['Tooltip.message.tagConfig.addMetric']}
              arrow
              placement="top-start"
            >
              <AddIcon
                data-testid={TEST_IDS.ADD_METRIC_ICON}
                height="1.5rem"
                width="1.5rem"
                onClick={handleAddMetric}
              />
            </CustomTooltip>
          ) : (
            <AddIconDisabled height="1.5rem" width="1.5rem" />
          )}
        </AddMetricIconContainer>
      </TagSourceAndDetailsContainer>
    );
  };

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={() => {
          onExpandUpdate(DIGITAL_TWIN_ACCORDIONS.TAG_CONFIG);
        }}
        disabled={!isTagConfigEnabled}
      >
        <Accordion.AccordionSummary
          expandIcon={
            isTagConfigEnabled ? (
              <AccordionDownArrow
                alt="down arrow"
                height="0.563rem"
                width="1rem"
              />
            ) : (
              <AccordionDisableArrow
                alt="disable arrow"
                height="0.563rem"
                width="1rem"
              />
            )
          }
          id={ACCORDION_IDS.TAG_CONFIG}
          data-testid={TEST_IDS.TAG_CONFIG_ACCORDION}
        >
          <AccordionTitleWrapper>
            <SubConfigTitle sx={{ marginRight: '0.625rem' }}>
              {MESSAGE_STRINGS['Config.header.tagConfiguration']}
            </SubConfigTitle>
            <CustomTooltip
              title={MESSAGE_STRINGS['Tooltip.message.tagConfiguration']}
              arrow
              placement="top-start"
              data-testid={TEST_IDS.TAG_CONFIG_ACCORDION_TOOLTIP}
            >
              <ToolTipIconBox>
                <InfoIcon alt="upload-info-icon" height="1rem" width="1rem" />
              </ToolTipIconBox>
            </CustomTooltip>
          </AccordionTitleWrapper>
          <FlexBox isRow>
            {accordionStatus === ACCORDION_STATUS.COMPLETED && (
              <AccordionStatus
                type={DIGITAL_TWIN_ACCORDIONS.TAG_CONFIG}
                status={`${numberOfConfiguredTags} of ${numberOfTags} Tags`}
                isMarginRight={true}
              />
            )}
            <AccordionStatus
              type={DIGITAL_TWIN_ACCORDIONS.TAG_CONFIG}
              status={accordionStatus}
            />
          </FlexBox>
        </Accordion.AccordionSummary>
        <Accordion.AccordionDetails
          data-testid={TEST_IDS.TAG_CONFIG_ACCORDION_DETAILS}
        >
          <FlexBox isRow>
            <TagListContainer>
              <ListPanel heading="">
                <List value={selectedTagName} disablePadding>
                  {tagConfigDetailsMeta?.map((tag) => (
                    <PanelListItem
                      key={`${tag.name}`}
                      isSelected={selectedTagName === tag.name}
                      id={tag.name}
                      label={tag.name}
                      tagSource={tag.tagSourceName}
                      onClick={() => {
                        handleOnTagSelect(tag.name);
                      }}
                    />
                  ))}
                </List>
              </ListPanel>
            </TagListContainer>
            {getCardDetails()}
          </FlexBox>
          <SubConfigFooter>
            <StyledButton
              disabled={!isSaveButtonEnabled}
              onClick={handleTagConfigSave}
              data-testid={TEST_IDS.SAVE_TAGS}
            >
              <ButtonTypography>{BUTTON_TEXT.SAVE}</ButtonTypography>
            </StyledButton>
          </SubConfigFooter>
        </Accordion.AccordionDetails>
      </Accordion>
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </>
  );
};

TagConfig.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  refetchTagConfigDetails: PropTypes.func,
};

export default TagConfig;
