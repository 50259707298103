import { createSlice } from '@reduxjs/toolkit';
import { SLICE_KEYS, SLICE_NAMES } from '../storeConstants';
import { tagDisplayDetails } from '../dataFormat';
import { getSelectedTagIndex } from '../../utils/helper';

const initialState = {
  [SLICE_KEYS.IS_TAG_CONFIG_ENABLED]: false,
  [SLICE_KEYS.TAG_SETTINGS_META]: {},
  [SLICE_KEYS.TAG_SETTINGS_METRICS_META]: {},
  [SLICE_KEYS.TAG_CONFIG_DETAILS_META]: [],
  [SLICE_KEYS.TAG_CONFIG_DETAILS]: [],
  [SLICE_KEYS.NUMBER_OF_TAGS]: 0,
  [SLICE_KEYS.NUMBER_OF_CONFIGURED_TAGS]: 0,
};

const tagConfigSlice = createSlice({
  name: SLICE_NAMES.TAG_CONFIG_SLICE,
  initialState: initialState,
  reducers: {
    resetTagConfigSlice: (state) => {
      Object.assign(state, initialState);
    },
    setIsTagConfigEnabled: (state, action) => {
      state[SLICE_KEYS.IS_TAG_CONFIG_ENABLED] = action.payload;
    },
    setTagSettingsMeta: (state, action) => {
      state[SLICE_KEYS.TAG_SETTINGS_META] = action.payload;
    },
    setTagSettingsMetricsMeta: (state, action) => {
      state[SLICE_KEYS.TAG_SETTINGS_METRICS_META] = action.payload;
    },
    setTagConfigDetails: (state, action) => {
      state[SLICE_KEYS.TAG_CONFIG_DETAILS_META] = action.payload.tags;
      state[SLICE_KEYS.TAG_CONFIG_DETAILS] = tagDisplayDetails(action.payload);
    },
    updateSelectedTagConfigDetails: (state, action) => {
      const updatedSelectedTagDetails = action.payload;
      const selectedTagIndex = getSelectedTagIndex(
        updatedSelectedTagDetails.name,
        state[SLICE_KEYS.TAG_CONFIG_DETAILS]
      );
      state[SLICE_KEYS.TAG_CONFIG_DETAILS][selectedTagIndex] =
        updatedSelectedTagDetails;
    },
    setNumberOfTags: (state, action) => {
      state[SLICE_KEYS.NUMBER_OF_TAGS] = action.payload;
    },
    setNumberOfConfiguredTags: (state, action) => {
      state[SLICE_KEYS.NUMBER_OF_CONFIGURED_TAGS] = action.payload;
    },
  },
});

export const {
  resetTagConfigSlice,
  setIsTagConfigEnabled,
  setTagSettingsMeta,
  setTagConfigDetails,
  updateSelectedTagConfigDetails,
  setTagSettingsMetricsMeta,
  setNumberOfTags,
  setNumberOfConfiguredTags,
} = tagConfigSlice.actions;

export default tagConfigSlice.reducer;
