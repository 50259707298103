import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

/**
 * @param {string} sliceName slice name from `SLICE_NAMES`
 * @param {array} keys array of keys of the wanted Slice
 * @returns object of keys & its values from store
 *
 * custom hook to read store data
 */
export default function useStoreData(sliceName = '', sliceKeys = []) {
  return useSelector(
    createSelector(
      sliceKeys.reduce((selectors, sliceKey) => {
        return selectors.concat((state) => state[sliceName][sliceKey]);
      }, []),
      (...results) => {
        const storeData = {};
        sliceKeys.forEach((sliceKey, idx) => {
          storeData[sliceKey] = results[idx];
        });
        return storeData;
      }
    )
  );
}
