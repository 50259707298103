import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  ListItemWrapper,
  SelectedMarker,
  StyledListItem,
  StyledListItemNameTypography,
  StyledListItemTypography,
} from '../../utils/styles';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import { TEST_IDS } from '../../constants';

function PanelListItem({ isSelected, id, label, onClick, tagSource }) {
  return (
    <>
      {isSelected && <SelectedMarker />}
      <ListItemWrapper>
        <StyledListItem
          data-testid={TEST_IDS.LIST_ITEM}
          selected={isSelected}
          key={id}
          value={id}
          onClick={onClick}
          disableRipple
        >
          <Box>
            <StyledListItemTypography isSelected={isSelected}>
              {label}
            </StyledListItemTypography>
            {!tagSource ? (
              <StyledListItemNameTypography>
                {MESSAGE_STRINGS['Config.header.tagConfigListNotConfigured']}
              </StyledListItemNameTypography>
            ) : (
              <StyledListItemNameTypography>
                {MESSAGE_STRINGS['Config.header.tagConfigListConfigured']}
                {tagSource}
              </StyledListItemNameTypography>
            )}
          </Box>
        </StyledListItem>
      </ListItemWrapper>
    </>
  );
}

PanelListItem.propTypes = {
  isSelected: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tagSource: PropTypes.string,
};

export default PanelListItem;
