import {
  METRIC_LIST_NAMES,
  METRIC_NAMES,
  PARAMETER_TAG_LEVEL,
} from '../constants';

/**
 * @param {*} bytes
 * @param {*} decimals
 *
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * @param {*} tagName
 * @param {*} tags
 *
 */
export const getSelectedTagDetails = (tagName, tags) => {
  if (tags?.length === 0) return null;
  if (!tagName) return tags[0];
  return tags?.find((tag) => tag.name === tagName);
};

/**
 * @param {*} tagName
 * @param {*} tags
 *
 */
export const getSelectedTagIndex = (tagName, tags) => {
  return tags?.findIndex((tag) => tag.name === tagName);
};

export const getParameterLevelList = (tagSettings, functionalArea) => {
  let parameterLevelList = [];
  tagSettings?.functionalAreaList?.forEach((setting) => {
    if (setting.name === functionalArea) {
      setting.parameterTags.forEach((parameterTag) => {
        parameterLevelList.push(parameterTag.name);
      });
    }
  });
  return parameterLevelList;
};

const getSelectedParameterLevelId = (
  tagSettings,
  functionalArea,
  parameterLevel
) => {
  let parameterLevelId;
  tagSettings?.functionalAreaList?.forEach((setting) => {
    if (setting.name === functionalArea) {
      setting.parameterTags.forEach((parameterTag) => {
        if (parameterTag.name === parameterLevel) {
          parameterLevelId = parameterTag.id;
        }
      });
    }
  });
  return parameterLevelId;
};

export const getAssetLevelList = (
  tagSettings,
  tagSettingsMetricsMeta,
  functionalArea,
  parameterLevel
) => {
  const assetLevelList = [];
  const parameterLevelId = getSelectedParameterLevelId(
    tagSettings,
    functionalArea,
    parameterLevel
  );
  tagSettingsMetricsMeta?.[functionalArea]?.[
    parameterLevelId
  ]?.assetList.forEach((asset) => {
    assetLevelList.push(asset.name);
  });
  return assetLevelList;
};

export const getSubAssetLevelList = (
  tagSettings,
  tagSettingsMetricsMeta,
  functionalArea,
  parameterLevel,
  selectedAsset
) => {
  const subAssetList = [];
  const parameterLevelId = getSelectedParameterLevelId(
    tagSettings,
    functionalArea,
    parameterLevel
  );
  let selectedAssetId;
  tagSettingsMetricsMeta?.[functionalArea]?.[
    parameterLevelId
  ]?.assetList.forEach((asset) => {
    if (asset.name === selectedAsset) {
      selectedAssetId = asset.id;
    }
  });
  tagSettingsMetricsMeta?.[functionalArea]?.[
    parameterLevelId
  ]?.subAssetList.forEach((subAsset) => {
    if (subAsset.assetId === selectedAssetId) {
      subAssetList.push(subAsset.name);
    }
  });
  return subAssetList;
};

export const getMetricList = (
  tagSettings,
  tagSettingsMetricsMeta,
  functionalArea,
  parameterLevel,
  selectedAsset,
  selectedSubAsset
) => {
  const metricList = [];
  const parameterLevelId = getSelectedParameterLevelId(
    tagSettings,
    functionalArea,
    parameterLevel
  );
  if (
    parameterLevel === PARAMETER_TAG_LEVEL.ASSET ||
    parameterLevel === PARAMETER_TAG_LEVEL.SUB_ASSET
  ) {
    let selectedId;
    const selectedList =
      parameterLevel === PARAMETER_TAG_LEVEL.ASSET
        ? METRIC_LIST_NAMES.ASSET_LIST
        : METRIC_LIST_NAMES.SUB_ASSET_LIST;
    const selectedItem =
      parameterLevel === PARAMETER_TAG_LEVEL.ASSET
        ? selectedAsset
        : selectedSubAsset;
    tagSettingsMetricsMeta?.[functionalArea]?.[parameterLevelId]?.[
      selectedList
    ].forEach((selectedListItem) => {
      if (selectedListItem.name === selectedItem) {
        selectedId = selectedListItem.id;
      }
    });
    tagSettingsMetricsMeta?.[functionalArea]?.[
      parameterLevelId
    ]?.metricList.forEach((metric) => {
      if (metric.parameterId === selectedId) {
        metricList.push(metric.name);
      }
    });
    return metricList;
  }
  tagSettingsMetricsMeta?.[functionalArea]?.[
    parameterLevelId
  ]?.metricList.forEach((metric) => {
    metricList.push(metric.name);
  });
  return metricList;
};

const isParameterTagLevelValid = (
  parameterTagLevel,
  assetLevel,
  subAssetLevel
) => {
  if (parameterTagLevel === PARAMETER_TAG_LEVEL.CELL) {
    return true;
  } else if (parameterTagLevel === PARAMETER_TAG_LEVEL.ASSET) {
    return Boolean(assetLevel);
  } else if (parameterTagLevel === PARAMETER_TAG_LEVEL.SUB_ASSET) {
    return Boolean(assetLevel && subAssetLevel);
  }
};

const isMetricsValid = (metrics) => {
  let isMetricsValid = true;
  metrics.forEach((metric) => {
    if (
      !(
        metric?.functionalArea &&
        isParameterTagLevelValid(
          metric?.parameterTagLevel,
          metric?.asset,
          metric?.subAsset
        ) &&
        metric?.metric
      )
    ) {
      isMetricsValid = false;
    }
  });
  return isMetricsValid;
};

export const isTagConfigurationValid = (tagConfiguration) =>
  tagConfiguration?.tagSourceName && isMetricsValid(tagConfiguration?.metrics);

export const getIsDropdownDisabled = ({
  metricName,
  tagSourceValue,
  functionalAreaValue,
  parameterTagLevelValue,
  assetLevelValue,
  subAssetLevelValue,
}) => {
  switch (metricName) {
    case METRIC_NAMES.FUNCTIONAL_AREA:
      return !tagSourceValue;
    case METRIC_NAMES.PARAMETER_TAG_LEVEL:
      return !functionalAreaValue;
    case METRIC_NAMES.ASSET:
      return !(
        parameterTagLevelValue === PARAMETER_TAG_LEVEL.ASSET ||
        parameterTagLevelValue === PARAMETER_TAG_LEVEL.SUB_ASSET
      );
    case METRIC_NAMES.SUB_ASSET:
      return !(
        parameterTagLevelValue === PARAMETER_TAG_LEVEL.SUB_ASSET &&
        assetLevelValue
      );
    case METRIC_NAMES.METRIC:
      if (parameterTagLevelValue === PARAMETER_TAG_LEVEL.ASSET) {
        return !assetLevelValue;
      } else if (parameterTagLevelValue === PARAMETER_TAG_LEVEL.SUB_ASSET) {
        return !subAssetLevelValue;
      }
      return !parameterTagLevelValue;
  }
};
