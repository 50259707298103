import { useRef } from 'react';
import { Box, FormControl, Input } from '@mui/material';
import GreenUploadIcon from '../../assets/greenUploadIcon.svg';
import { TEST_IDS } from '../../constants';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import {
  CustomUploadBoxButtonBase,
  UploadBoxTypography,
} from '../../utils/styles';

const UploadInput = (inputComponentProps) => {
  const { accept, onFiles, getFilesFromEvent } = inputComponentProps;
  const hiddenFileInput = useRef(null);

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const chosenFile = getFilesFromEvent(event);
    onFiles(chosenFile);
  };
  return (
    <FormControl htmlFor="uploadButton" style={{ width: '100%' }}>
      <Input
        inputProps={{
          type: 'file',
          accept,
          ref: hiddenFileInput,
        }}
        id="uploadButton"
        name="uploadButton"
        data-testId={TEST_IDS.UPLOAD_INPUT_BTN}
        disableUnderline
        onChange={handleChange}
        sx={{ display: 'none' }}
      />
      <CustomUploadBoxButtonBase
        onClick={handleClickUpload}
        data-testid={TEST_IDS.INITIAL_UPLOAD_BUTTON}
        disableRipple
      >
        <GreenUploadIcon height="3.125rem" width="3.125rem" alt="upload-icon" />
        <Box sx={{ marginTop: '0.625rem' }}>
          <UploadBoxTypography>
            {MESSAGE_STRINGS['UploadModel.message.fileUpload']}
          </UploadBoxTypography>
        </Box>
      </CustomUploadBoxButtonBase>
    </FormControl>
  );
};

export default UploadInput;
