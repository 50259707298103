import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getFunctionalAreaListSelector } from '../../store/storeSelectors';
import ComboBox from '../ComboBox';
import CustomTooltip from '../CustomTooltip';
import DeleteIcon from '../../assets/deleteIcon.svg';
import DeleteIconDisabled from '../../assets/deleteIconDisabled.svg';
import { METRIC_NAMES, PLACEHOLDER, TEST_IDS } from '../../constants';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import {
  getAssetLevelList,
  getIsDropdownDisabled,
  getMetricList,
  getParameterLevelList,
  getSubAssetLevelList,
} from '../../utils/helper';
import {
  DeleteMetricIconContainer,
  FlexBox,
  MetricsDropdownWrapper,
  ParameterTagLevelContainer,
} from '../../utils/styles';
import useStoreData from '../../utils/hooks/useStoreData';
import { SLICE_KEYS, SLICE_NAMES } from '../../store/storeConstants';

const TagMetricsRow = ({
  index,
  handleDeleteMetric,
  metricInstance,
  onChange,
  selectedTagDetails,
  refetchTagConfigMetricsSettings,
}) => {
  const {
    [SLICE_KEYS.TAG_SETTINGS_META]: tagSettings,
    [SLICE_KEYS.TAG_SETTINGS_METRICS_META]: tagSettingsMetricsMeta,
  } = useStoreData(
    [SLICE_NAMES.TAG_CONFIG_SLICE],
    [SLICE_KEYS.TAG_SETTINGS_META, SLICE_KEYS.TAG_SETTINGS_METRICS_META]
  );
  const functionalAreaList = useSelector(getFunctionalAreaListSelector);
  return (
    <FlexBox
      key={index}
      isRow
      sx={{ marginTop: index > 0 ? '1.25rem' : '0rem' }}
    >
      <MetricsDropdownWrapper>
        <ComboBox
          placeholder={PLACEHOLDER}
          options={functionalAreaList}
          isDisabled={getIsDropdownDisabled({
            metricName: METRIC_NAMES.FUNCTIONAL_AREA,
            tagSourceValue: selectedTagDetails?.tagSourceName,
          })}
          value={metricInstance?.functionalArea ?? ''}
          onChange={onChange}
          index={index}
          metricName={METRIC_NAMES.FUNCTIONAL_AREA}
        />
      </MetricsDropdownWrapper>
      <ParameterTagLevelContainer>
        <MetricsDropdownWrapper>
          <ComboBox
            placeholder={PLACEHOLDER}
            options={getParameterLevelList(
              tagSettings,
              metricInstance?.functionalArea
            )}
            isDisabled={getIsDropdownDisabled({
              metricName: METRIC_NAMES.PARAMETER_TAG_LEVEL,
              functionalAreaValue: metricInstance?.functionalArea,
            })}
            value={metricInstance?.parameterTagLevel ?? ''}
            onChange={onChange}
            index={index}
            metricName={METRIC_NAMES.PARAMETER_TAG_LEVEL}
          />
        </MetricsDropdownWrapper>
        <MetricsDropdownWrapper
          onClick={() => {
            refetchTagConfigMetricsSettings();
          }}
        >
          <ComboBox
            placeholder={PLACEHOLDER}
            options={getAssetLevelList(
              tagSettings,
              tagSettingsMetricsMeta,
              metricInstance?.functionalArea,
              metricInstance?.parameterTagLevel
            )}
            isDisabled={getIsDropdownDisabled({
              metricName: METRIC_NAMES.ASSET,
              parameterTagLevelValue: metricInstance?.parameterTagLevel,
            })}
            value={metricInstance?.asset ?? ''}
            onChange={onChange}
            index={index}
            metricName={METRIC_NAMES.ASSET}
          />
        </MetricsDropdownWrapper>
        <MetricsDropdownWrapper>
          <ComboBox
            placeholder={PLACEHOLDER}
            options={getSubAssetLevelList(
              tagSettings,
              tagSettingsMetricsMeta,
              metricInstance?.functionalArea,
              metricInstance?.parameterTagLevel,
              metricInstance?.asset
            )}
            isDisabled={getIsDropdownDisabled({
              metricName: METRIC_NAMES.SUB_ASSET,
              parameterTagLevelValue: metricInstance?.parameterTagLevel,
              assetLevelValue: metricInstance?.asset,
            })}
            value={metricInstance?.subAsset ?? ''}
            onChange={onChange}
            index={index}
            metricName={METRIC_NAMES.SUB_ASSET}
          />
        </MetricsDropdownWrapper>
      </ParameterTagLevelContainer>
      <MetricsDropdownWrapper
        onClick={() => {
          refetchTagConfigMetricsSettings();
        }}
      >
        <ComboBox
          placeholder={PLACEHOLDER}
          options={getMetricList(
            tagSettings,
            tagSettingsMetricsMeta,
            metricInstance?.functionalArea,
            metricInstance?.parameterTagLevel,
            metricInstance?.asset,
            metricInstance?.subAsset
          )}
          isDisabled={getIsDropdownDisabled({
            metricName: METRIC_NAMES.METRIC,
            parameterTagLevelValue: metricInstance?.parameterTagLevel,
            assetLevelValue: metricInstance?.asset,
            subAssetLevelValue: metricInstance?.subAsset,
          })}
          value={metricInstance?.metric ?? ''}
          onChange={onChange}
          index={index}
          metricName={METRIC_NAMES.METRIC}
        />
      </MetricsDropdownWrapper>
      <DeleteMetricIconContainer
        metricsLength={selectedTagDetails?.metrics?.length}
      >
        {selectedTagDetails?.metrics?.length > 1 ? (
          <CustomTooltip
            title={MESSAGE_STRINGS['Tooltip.message.tagConfig.deleteMetric']}
            arrow
            placement="top-start"
          >
            <DeleteIcon
              data-testid={TEST_IDS.DELETE_METRIC_ICON}
              height="1.5rem"
              width="1.5rem"
              onClick={() => handleDeleteMetric(index)}
            />
          </CustomTooltip>
        ) : (
          <DeleteIconDisabled height="1.5rem" width="1.5rem" />
        )}
      </DeleteMetricIconContainer>
    </FlexBox>
  );
};

const metricType = {
  functionalArea: PropTypes.string,
  parameterTagLevel: PropTypes.string,
  asset: PropTypes.string,
  subAsset: PropTypes.string,
  metric: PropTypes.string,
};

TagMetricsRow.propTypes = {
  index: PropTypes.number,
  handleDeleteMetric: PropTypes.func,
  metricInstance: PropTypes.arrayOf(metricType),
  onChange: PropTypes.func,
  refetchTagConfigMetricsSettings: PropTypes.func,
  selectedTagDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tagSourceName: PropTypes.string,
    linkDestination: PropTypes.string,
    metrics: PropTypes.arrayOf(metricType),
  }),
};

export default TagMetricsRow;
