import { digitalTwin } from '@smf/ui-util-app';

export async function getDigitalTwinConfig(entityId) {
  return digitalTwin.getDigitalTwinConfig(entityId);
}

export async function updateDigitalTwinConfig(body) {
  return digitalTwin.updateDigitalTwinConfig(body);
}

export async function getPresignedUploadUrl(body) {
  return digitalTwin.initiateFileUpload(body);
}

export async function validateFileUpload(body) {
  return digitalTwin.validateFileUpload(body);
}

export async function saveTagDetails(body) {
  return digitalTwin.saveTags(body);
}

export async function getDigitalTwinTagConfig(factoryId) {
  return digitalTwin.getDigitalTwinTagConfig(factoryId);
}

export async function getTagConfigSettings(factoryId) {
  return digitalTwin.getTagConfigSettings(factoryId);
}

export async function getDigitalTwinTagMetricsSettings(body) {
  return digitalTwin.getTagConfigMetricsSettings(body);
}

export async function updateDigitalTwinTagConfig(body) {
  return digitalTwin.updateDigitalTwinTagConfig(body);
}

export async function getDigitalTwinUserGuidePdf(factoryId) {
  return digitalTwin.getDigitalTwinUserGuidePdf(factoryId);
}
