import { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import AccordionDownArrow from '../../assets/downArrow.svg';
import AccordionDisableArrow from '../../assets/CaretDisableArrow.svg';
import CustomTooltip from '../CustomTooltip';
import { StyledAutocomplete, StyledPaper } from '../../utils/styles';
import { TEST_IDS } from '../../constants';
import { MESSAGE_STRINGS } from '../../constants/en-us';

function CustomPaper({ children }) {
  return <StyledPaper>{children}</StyledPaper>;
}

CustomPaper.propTypes = {
  children: PropTypes.node,
};

const ComboBox = ({
  placeholder,
  options,
  isDisabled,
  onChange,
  value,
  index,
  metricName,
}) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [defaultVal, setDefaultVal] = useState(value);

  const textElementRef = useRef(null);

  const handleChange = (_, value) => {
    onChange(value, index, metricName);
  };

  const renderTextField = (params) => {
    return (
      <TextField
        inputRef={textElementRef}
        {...params}
        placeholder={placeholder}
      />
    );
  };

  useEffect(() => {
    setIsOverflowed(
      textElementRef.current.scrollWidth > textElementRef.current.offsetWidth
    );
  }, [textElementRef.current, defaultVal]);
  return (
    <StyledAutocomplete
      disableClearable
      onInputChange={(_, value) => {
        setDefaultVal(value);
      }}
      ListboxProps={{ style: { maxHeight: '12.6rem', overflow: 'auto' } }}
      popupIcon={
        isDisabled ? (
          <AccordionDisableArrow
            alt="disable arrow"
            height="0.46rem"
            width="0.75rem"
          />
        ) : (
          <AccordionDownArrow height="0.46rem" width="0.75rem" />
        )
      }
      disablePortal
      id="combo-box-demo"
      value={value}
      options={
        options.length === 0
          ? [MESSAGE_STRINGS['TagConfig.dropdownOptions.nda.text']]
          : options
      }
      getOptionDisabled={(option) =>
        option === MESSAGE_STRINGS['TagConfig.dropdownOptions.nda.text']
      }
      renderInput={(params) =>
        !isOverflowed ? (
          renderTextField(params)
        ) : (
          <CustomTooltip title={value} arrow placement="top-start">
            {renderTextField(params)}
          </CustomTooltip>
        )
      }
      PaperComponent={CustomPaper}
      disabled={isDisabled}
      onChange={handleChange}
      data-testid={TEST_IDS.COMBO_BOX}
    />
  );
};

ComboBox.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  index: PropTypes.number,
  metricName: PropTypes.string,
};

export default ComboBox;
