const DIGITAL_TWIN_ACCORDIONS = {
  DISPLAY_SETTINGS: 'display-settings',
  MODEL_UPLOAD: 'digital-twin-model-upload',
  TAG_CONFIG: 'tag-configuration',
};

const QUERY_CONSTANTS = {
  GET_DT_CONFIG: 'get-digital-twin-config',
  POST_DT_CONFIG: 'post-digital-twin-config',
  VALIDATE_FILE: 'put-validate-file',
  POST_TAG_DETAILS: 'post-tag-details',
  GET_TAG_CONFIG_SETTINGS: 'get-tag-config-settings',
  GET_TAG_CONFIG_DETAILS: 'get-tag-config-details',
  GET_TAG_CONFIG_METRICS_SETTINGS: 'get-tag-config-metrics-settings',
  UPDATE_TAG_CONFIG_DETAILS: 'update-tag-config-details',
  GET_USER_GUIDE_PDF: 'get-user-guide-pdf',
};

const ACCORDION_IDS = {
  DISPLAY_SETTINGS: 'display-settings',
  MODEL_UPLOAD: 'model-upload',
  TAG_CONFIG: 'tag-config',
};

const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

const TEST_IDS = {
  BACKDROP: 'backdrop',
  DISPLAY_SETTINGS_ACCORDION: 'display-settings-accordion',
  DISPLAY_SETTINGS_ACCORDION_DOWN_ARROW:
    'display-settings-accordion-down-arrow',
  MODEL_UPLOAD_ACCORDION: 'model-upload-accordion',
  MODEL_UPLOAD_ACCORDION_TOOLTIP: 'model-upload-accordion-tooltip',
  MODEL_UPLOAD_ACCORDION_TOOLTIP_ICON: 'model-upload-accordion-tooltip-icon',
  DISPLAY_SETTINGS_CONTENT: 'display-settings-content',
  MODEL_UPLOAD_CONTENT: 'model-upload-content',
  DISPLAY_SETTINGS_SAVE: 'display-settings-save-btn',
  DT_TOGGLE: 'digital-twin-switch',
  ACCORDION_STATUS: 'accordion-status',
  ACCORDION_STATUS_VALUE: 'accordion-status-value',
  INITIAL_UPLOAD_BUTTON: 'initial_upload_button',
  DROPZONE: 'dropzone',
  DROPZONE_UPLOADER: 'dropzone file uploader',
  FILE_REUPLOAD: 'file-reupload-btn',
  FILE_REUPLOAD_ICON: 'file-reupload-icon',
  SAVE_TAGS: 'save-tags',
  USER_GUIDE_ICON: 'user-guide-icon',
  PREVIEW_ERROR: 'preview-error',
  ERROR_UPLOAD_BTN: 'error-upload-button',
  UPLOAD_INPUT_BTN: 'upload-input',
  SCENE_CONTAINER: 'dt-scene',
  SECONDARY_UPLOAD_BOX: 'secondary-upload',
  TAG_CONFIG_ACCORDION: 'tag-config-accordion',
  TAG_CONFIG_ACCORDION_DETAILS: 'tag-config-accordion-details',
  TAG_CONFIG_ACCORDION_TOOLTIP: 'tag-config-accordion-tooltip',
  TAG_CONFIG_ACCORDION_TOOLTIP_ICON: 'tag-config-accordion-tooltip-icon',
  CARD_DETAILS_CONTAINER: 'card-details-container',
  LIST_ITEM: 'list-item',
  ADD_METRIC_ICON: 'add-metric-icon',
  DELETE_METRIC_ICON: 'delete-metric-icon',
  COMBO_BOX: 'combo-box',
};

const ACCORDION_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

const FILE_CONTENT_TYPES = {
  GLB_GLTF: 'model/gltf-binary',
};

const FILE_ACCEPT_TYPE = '.glb,.gltf';

const FILE_UPLOAD_STATUSES = {
  DONE: 'done',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
  RESTARTED: 'restarted',
  REMOVED: 'removed',
  STARTED: 'started',
  READY: 'ready',
  PREPARING: 'preparing',
  GETTING_UPLOAD_PARAMS: 'getting_upload_params',
  HEADERS_RECEIVED: 'headers_received',
  REJECTED_FILE: 'rejected_file_type',
  UPLOAD_PARAMS_ERROR: 'error_upload_params',
};

const PLACEHOLDER = 'Enter or Select';

const METRIC_NAMES = {
  TAG_SOURCE: 'tagSource',
  LINK_DESTINATION: 'linkDestination',
  FUNCTIONAL_AREA: 'functionalArea',
  PARAMETER_TAG_LEVEL: 'parameterTagLevel',
  ASSET: 'asset',
  SUB_ASSET: 'subAsset',
  METRIC: 'metric',
};

const PARAMETER_TAG_LEVEL = {
  CELL: 'Cell',
  ASSET: 'Asset',
  SUB_ASSET: 'Sub-Asset',
};

const DEFAULT_METRICS = {
  functionalArea: null,
  parameterTagLevel: null,
  asset: null,
  subAsset: null,
  metric: null,
};

const METRIC_LIST_NAMES = {
  ASSET_LIST: 'assetList',
  SUB_ASSET_LIST: 'subAssetList',
};

export {
  DIGITAL_TWIN_ACCORDIONS,
  QUERY_CONSTANTS,
  ACCORDION_IDS,
  TOAST_TYPE,
  TOAST_REDUCER_CONSTANTS,
  TEST_IDS,
  ACCORDION_STATUS,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
  FILE_ACCEPT_TYPE,
  PLACEHOLDER,
  METRIC_NAMES,
  PARAMETER_TAG_LEVEL,
  DEFAULT_METRICS,
  METRIC_LIST_NAMES,
};
