import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { DangerText, StyledBackdrop } from '../../utils/styles';

export default function LoadingIndicator({ size }) {
  return (
    <>
      <StyledBackdrop invisible open />
      <DangerText>
        <CircularProgress color="inherit" size={size} />
      </DangerText>
    </>
  );
}

LoadingIndicator.propTypes = {
  size: PropTypes.number,
};
