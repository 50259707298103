import { ThemeProvider } from '@mui/material';
import {
  globalstate$,
  theme,
  splitRootFactory,
  FEATURE_FLAGS,
} from '@smf/ui-util-app';
import {
  SplitClient,
  SplitFactory,
  SplitTreatments,
} from '@splitsoftware/splitio-react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import App from './containers/Routes';
import { ToastProvider } from './context/toastContext';
import store from './store';
import { MESSAGE_STRINGS } from './constants/en-us';

export default function Root() {
  const queryClient = new QueryClient();

  const renderApp = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <App />
            </ToastProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    );
  };
  return (
    <SplitFactory factory={splitRootFactory}>
      <SplitClient
        splitKey={globalstate$._value?.userData?.email?.toLowerCase()}
      >
        <SplitTreatments names={[FEATURE_FLAGS.DT]}>
          {({ isReady }) => {
            return isReady ? (
              renderApp()
            ) : (
              <>{MESSAGE_STRINGS['LoadingIndicator.message']}</>
            );
          }}
        </SplitTreatments>
      </SplitClient>
    </SplitFactory>
  );
}
