import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Dropzone from 'react-dropzone-uploader';
import UploadInput from '../UploadInput';
import UploadPreview from '../UploadPreview';
import {
  UploadBoxContainer,
  UploadModelAccordionContent,
} from '../../utils/styles';
import { FILE_ACCEPT_TYPE, TEST_IDS } from '../../constants';

const UploadBox = forwardRef((props, ref) => {
  const {
    getUploadParams,
    handleChangeStatus,
    isFilePreparing,
    isValidateError,
    handleReupload,
  } = props;

  const getPreviewComponent = (previewProps) => {
    return (
      <UploadPreview
        previewComponentProps={previewProps}
        isFilePreparing={isFilePreparing}
        isValidateError={isValidateError}
        handleReupload={handleReupload}
      />
    );
  };
  return (
    <UploadModelAccordionContent>
      <UploadBoxContainer>
        <Box data-testid={TEST_IDS.DROPZONE}>
          <Dropzone
            ref={ref}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            maxFiles={1}
            PreviewComponent={(previewProps) =>
              getPreviewComponent(previewProps)
            }
            InputComponent={UploadInput}
            accept={FILE_ACCEPT_TYPE}
            multiple={false}
            canRestart
          />
        </Box>
      </UploadBoxContainer>
    </UploadModelAccordionContent>
  );
});

export default UploadBox;
UploadBox.displayName = UploadBox;
UploadBox.propTypes = {
  getUploadParams: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  isFilePreparing: PropTypes.bool,
  handleReupload: PropTypes.func,
  isValidateError: PropTypes.bool,
};
