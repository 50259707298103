import { configureStore } from '@reduxjs/toolkit';
import { SLICE_NAMES } from './storeConstants';
import tagConfigSlice from './slices/tagConfigSlice';
import masterSlice from './slices/masterSlice';

const store = configureStore({
  reducer: {
    [SLICE_NAMES.MASTER_SLICE]: masterSlice,
    [SLICE_NAMES.TAG_CONFIG_SLICE]: tagConfigSlice,
  },
  devTools: { name: 'SMF DT Config' },
});

export default store;
