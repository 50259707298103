import PropTypes from 'prop-types';
import InfoIcon from '../../assets/infoIcon.svg';
import {
  DropdownLabelAndTooltipWrapper,
  DropdownLabelTypography,
  ToolTipIconBox,
} from '../../utils/styles';
import CustomTooltip from '../CustomTooltip';

const DropdownLabel = ({ label = '', tooltipMessage = '' }) => {
  return (
    <DropdownLabelAndTooltipWrapper>
      <DropdownLabelTypography>{label}</DropdownLabelTypography>
      <CustomTooltip title={tooltipMessage} arrow placement="top-start">
        <ToolTipIconBox>
          <InfoIcon alt="upload-info-icon" height="1rem" width="1rem" />
        </ToolTipIconBox>
      </CustomTooltip>
    </DropdownLabelAndTooltipWrapper>
  );
};

DropdownLabel.propTypes = {
  label: PropTypes.string,
  tooltipMessage: PropTypes.string,
};

export default DropdownLabel;
