const MESSAGE_STRINGS = {
  'Config.page.Title': 'Digital Twin',
  'Config.header.uisettings': 'UI Settings',
  'Config.header.displaySettings': 'Display Settings',
  'DisplaySettings.Enable_digital_twin': 'Enable Digital Twin',
  'Config.header.modelFileUpload': 'Digital Twin Model Upload',
  'Config.header.tagConfiguration': 'Tag Configuration',
  'Config.header.tagConfigListNotConfigured': 'Not Configured',
  'Config.header.tagConfigListConfigured': 'Configured: ',

  //Toast
  'Toast.message.SUCCESS': 'Changes have been saved successfully!',
  'Toast.message.upload.SUCCESS': 'File has been successfully uploaded!',
  'Toast.message.modelTagSave.SUCCESS': 'Model has been succcssfully saved!',
  'Toast.message.tagConfigDetailsUpdate.SUCCESS':
    'Your tag configuration has been successfully updated!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',

  //Model Upload
  'UploadModel.message.fileUpload':
    'Upload .GLB, .GLTF  •  Maximum size: 100MB',
  'UploadModel.message.reupload': 'Upload .GLB, .GLTF',
  'UploadModel.message.userguide': 'User Guide',
  'UploadModel.reupload.preview.text': 'Re-Upload',

  //Tag Configuration
  'TagConfig.dropdownLabel.tagSource': 'Tag Source',
  'TagConfig.dropdownLabel.linkDestination': 'Link Destination',
  'TagConfig.dropdownLabel.functionalArea': 'Functional Area',
  'TagConfig.dropdownLabel.parameterTagLevel': 'Parameter Tag Level',
  'TagConfig.dropdownLabel.metric': 'Metric',
  'TagConfig.header.cardDetails': 'Card Details',
  'TagConfig.dropdownOptions.nda.text': 'No data selections available',

  //Tooltip messages
  'Tooltip.message.modelUpload':
    'Use this tab to upload a 3D model file to render a Digital Twin display. For first-time users, first review the User Guide for detailed instructions and step-by-step guidance on how to successfully configure the Digital Twin feature within IntelligentOps.',
  'Tooltip.message.modelReupload':
    'If you have already uploaded a .GLB or .GLTF file, clicking this icon again will allow you to upload a new file that will replace the previous file. All data from the first file will be removed.',
  'Tooltip.message.tagConfiguration':
    'Use this tab to identify tag configuration settings for each specific digital twin tag including data sources and navigation links.',
  'Tooltip.message.tagConfig.tagName':
    'Use this tab to define tag level settings to configure the digital twin tag display.',
  'Tooltip.message.tagConfig.tagSource':
    'Select the equipment hierarchy level that the tag is associated with.',
  'Tooltip.message.tagConfig.linkDestination':
    'Select 1 custom page (per card) to navigate to within the application.',
  'Tooltip.message.tagConfig.functionalArea':
    'Select the system module directly related to your metric of interest.',
  'Tooltip.message.tagConfig.parameterTagLevel':
    'Select the parameter tag level at which the metric is calculated (e.g., Cell, Asset,Sub-asset).',
  'Tooltip.message.tagConfig.metric':
    'Select the metric(s) to be displayed within a single card (up to a maximum of 4)',
  'Tooltip.message.tagConfig.addMetric': 'Add metric',
  'Tooltip.message.tagConfig.deleteMetric': 'Delete metric',

  //Feature Flag
  'LoadingIndicator.message': 'Loading...',
};

const BUTTON_TEXT = {
  SAVE: 'Save',
};

export { MESSAGE_STRINGS, BUTTON_TEXT };
