import { Box, ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { digitalTwinModelDataSelector } from '../../store/storeSelectors';
import UserGuideIcon from '../../assets/infoV2Icon.svg';
import InfoIcon from '../../assets/infoIcon.svg';
import UploadIcon from '../../assets/iconUpload.svg';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import CustomTooltip from '../CustomTooltip';
import {
  HeaderBox,
  ReuploadTypography,
  ReuploadWrapper,
  UserGuideTypography,
  UserGuideWrapper,
} from '../../utils/styles';
import { QUERY_CONSTANTS, TEST_IDS } from '../../constants';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { getDigitalTwinUserGuidePdf } from '../../utils/apiHelper';

const UploadBoxHeader = ({ handleReupload, setIsUserGuidePdfUrlFetching }) => {
  const { rxjsState } = useRxjsState();
  const isModelFileUploaded = useSelector(digitalTwinModelDataSelector);

  const { mutate: getUserGuidePdfUrl } = useMutation(
    [QUERY_CONSTANTS.GET_USER_GUIDE_PDF],
    async () => {
      return getDigitalTwinUserGuidePdf(rxjsState.plantId);
    },
    {
      onSuccess: (res) => {
        setIsUserGuidePdfUrlFetching(false);
        window.open(res.data, '_blank');
      },
    }
  );

  const handleUserGuideIconClick = () => {
    setIsUserGuidePdfUrlFetching(true);
    getUserGuidePdfUrl();
  };

  return (
    <HeaderBox>
      <UserGuideWrapper>
        <ButtonBase
          onClick={handleUserGuideIconClick}
          disableRipple
          data-testid={TEST_IDS.USER_GUIDE_ICON}
        >
          <UserGuideIcon
            width="1.8462rem"
            height="1.8462rem"
            alt="user-guide-icon"
          />
          <UserGuideTypography>
            {MESSAGE_STRINGS['UploadModel.message.userguide']}
          </UserGuideTypography>
        </ButtonBase>
      </UserGuideWrapper>
      {isModelFileUploaded && (
        <ReuploadWrapper>
          <ButtonBase
            onClick={handleReupload}
            disableRipple
            data-testid={TEST_IDS.FILE_REUPLOAD}
          >
            <UploadIcon
              width="1.8462rem"
              height="1.8462rem"
              alt="reupload-icon"
              data-testid={TEST_IDS.FILE_REUPLOAD_ICON}
            />
            <ReuploadTypography>
              {MESSAGE_STRINGS['UploadModel.message.reupload']}
            </ReuploadTypography>
          </ButtonBase>
          <Box sx={{ margin: '0.3rem 0rem 0rem 0.5rem' }}>
            <CustomTooltip
              title={MESSAGE_STRINGS['Tooltip.message.modelReupload']}
              arrow
              placement="top-start"
            >
              <InfoIcon alt="info icon" height="1rem" width="1rem" />
            </CustomTooltip>
          </Box>
        </ReuploadWrapper>
      )}
    </HeaderBox>
  );
};

UploadBoxHeader.propTypes = {
  handleReupload: PropTypes.func,
  setIsUserGuidePdfUrlFetching: PropTypes.func,
};

export default UploadBoxHeader;
