import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Accordion from '../../components/Accordion';
import { SLICE_KEYS, SLICE_NAMES } from '../../store/storeConstants';
import {
  ACCORDION_IDS,
  DIGITAL_TWIN_ACCORDIONS,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { BUTTON_TEXT, MESSAGE_STRINGS } from '../../constants/en-us';
import {
  BackdropContainer,
  ButtonTypography,
  CustomBackdrop,
  StyledButton,
  SubConfigContentTypography,
  SubConfigContentWrapper,
  SubConfigFooter,
  SubConfigTitle,
} from '../../utils/styles';
import Switch from '../../components/Switch';
import AccordionDownArrow from '../../assets/downArrow.svg';
import AccordionDisableArrow from '../../assets/CaretDisableArrow.svg';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { useToastContext } from '../../context/toastContext';
import { updateDigitalTwinConfig } from '../../utils/apiHelper';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useSelector } from 'react-redux';
import { digitalTwinConfigFlagSelector } from '../../store/storeSelectors';
import useStoreData from '../../utils/hooks/useStoreData';

const DisplaySettings = ({
  onExpandUpdate,
  isExpanded,
  refetchDigitalTwinConfig,
}) => {
  const { rxjsState } = useRxjsState();

  const {
    [SLICE_KEYS.IS_MODEL_UPLOADED]: isModelUploaded,
    [SLICE_KEYS.IS_TAG_CONFIG_COMPLETED]: isTagConfigCompleted,
  } = useStoreData(
    [SLICE_NAMES.MASTER_SLICE],
    [SLICE_KEYS.IS_MODEL_UPLOADED, SLICE_KEYS.IS_TAG_CONFIG_COMPLETED]
  );

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [isUiSettingsBtnDisabled, setIsUiSettingsBtnDisabled] = useState(true);

  const isDigitalTwinEnabled = useSelector(digitalTwinConfigFlagSelector);

  const handleSwitchChange = (event) => {
    setIsSwitchChecked(event.target.checked);
  };

  useEffect(() => {
    setIsSwitchChecked(isDigitalTwinEnabled);
  }, [isDigitalTwinEnabled]);

  useEffect(() => {
    if (isDigitalTwinEnabled !== isSwitchChecked) {
      setIsUiSettingsBtnDisabled(false);
    } else {
      setIsUiSettingsBtnDisabled(isDigitalTwinEnabled === isSwitchChecked);
    }
  }, [isSwitchChecked, isDigitalTwinEnabled]);

  const { toastDispatch } = useToastContext();
  /* 
    Update Digital Twin Config details
  */
  const {
    mutate: updateDigitalTwinConfigMutate,
    isLoading: isUpdateDigitalTwinConfigLoading,
  } = useMutation(
    [QUERY_CONSTANTS.POST_DT_CONFIG],
    async (reqBody) => {
      return updateDigitalTwinConfig(reqBody);
    },
    {
      onSuccess: () => {
        refetchDigitalTwinConfig();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.SUCCESS'],
          },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS['Toast.message.ERROR'],
          },
        });
      },
      enabled: rxjsState?.plantId,
    }
  );

  const handleUiSettingsConfigSave = () => {
    const reqBody = {
      isDigitalTwinEnabled: isSwitchChecked,
      entityId: rxjsState.plantId,
    };
    updateDigitalTwinConfigMutate(reqBody);
  };

  const showBackdropLoader = isUpdateDigitalTwinConfigLoading;
  const isDisplayAccordionEnabled = isModelUploaded && isTagConfigCompleted;

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={() => {
          onExpandUpdate(DIGITAL_TWIN_ACCORDIONS.DISPLAY_SETTINGS);
        }}
        disabled={!isDisplayAccordionEnabled}
        id={ACCORDION_IDS.DISPLAY_SETTINGS}
      >
        <Accordion.AccordionSummary
          data-testid={TEST_IDS.DISPLAY_SETTINGS_ACCORDION}
          expandIcon={
            isDisplayAccordionEnabled ? (
              <AccordionDownArrow
                alt="down arrow"
                height="0.563rem"
                width="1rem"
                data-testid={TEST_IDS.DISPLAY_SETTINGS_ACCORDION_DOWN_ARROW}
              />
            ) : (
              <AccordionDisableArrow
                alt="disable arrow"
                height="0.563rem"
                width="1rem"
              />
            )
          }
        >
          <SubConfigTitle>
            {MESSAGE_STRINGS['Config.header.displaySettings']}
          </SubConfigTitle>
        </Accordion.AccordionSummary>
        <Accordion.AccordionDetails
          data-testid={TEST_IDS.DISPLAY_SETTINGS_CONTENT}
        >
          <Box>
            <SubConfigContentWrapper>
              <Box sx={{ marginLeft: '1rem' }}>
                <SubConfigContentTypography>
                  {MESSAGE_STRINGS['DisplaySettings.Enable_digital_twin']}
                </SubConfigContentTypography>
              </Box>
              <Box sx={{ marginLeft: '1.75rem' }}>
                <Switch
                  onChange={handleSwitchChange}
                  checked={isSwitchChecked}
                />
              </Box>
            </SubConfigContentWrapper>
            <SubConfigFooter>
              <StyledButton
                disabled={isUiSettingsBtnDisabled}
                onClick={handleUiSettingsConfigSave}
                data-testid={TEST_IDS.DISPLAY_SETTINGS_SAVE}
              >
                <ButtonTypography>{BUTTON_TEXT.SAVE}</ButtonTypography>
              </StyledButton>
            </SubConfigFooter>
          </Box>
        </Accordion.AccordionDetails>
      </Accordion>
      <BackdropContainer data-testid={TEST_IDS.BACKDROP}>
        <CustomBackdrop open={showBackdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </BackdropContainer>
    </>
  );
};

DisplaySettings.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  refetchDigitalTwinConfig: PropTypes.func,
};
export default DisplaySettings;
