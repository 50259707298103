const SLICE_NAMES = {
  TAG_CONFIG_SLICE: 'TAG_CONFIG',
  MASTER_SLICE: 'MASTER_SLICE',
};

const SLICE_KEYS = {
  IS_MODEL_UPLOADED: 'IS_MODEL_UPLOADED',
  IS_DT_ENABLED: 'IS_DT_ENABLED',
  IS_TAG_CONFIG_ENABLED: 'IS_TAG_CONFIG_ENABLED',
  TAG_SETTINGS_META: 'TAG_SETTINGS_META',
  TAG_SETTINGS_METRICS_META: 'TAG_SETTINGS_METRICS_META',
  TAG_CONFIG_DETAILS_META: 'TAG_CONFIG_DETAILS_META',
  TAG_CONFIG_DETAILS: 'TAG_CONFIG_DETAILS',
  TAG_NAME_LIST: 'TAG_NAME_LIST',
  NUMBER_OF_TAGS: 'NUMBER_OF_TAGS',
  NUMBER_OF_CONFIGURED_TAGS: 'NUMBER_OF_CONFIGURED_TAGS',
  IS_TAG_CONFIG_COMPLETED: 'IS_TAG_CONFIG_COMPLETED',
};

export { SLICE_NAMES, SLICE_KEYS };
