import PropTypes from 'prop-types';
import { DropdownLabelWrapper } from '../../utils/styles';
import ComboBox from '../ComboBox';
import { PLACEHOLDER } from '../../constants';
import DropdownLabel from '../DropdownLabel';

const NamedCombobox = ({
  label = '',
  tooltipMessage = '',
  placeholder = PLACEHOLDER,
  options = [],
  isDisabled = false,
  onSelectionChanged = () => {},
  value = '',
  metricName = '',
}) => {
  return (
    <DropdownLabelWrapper>
      <DropdownLabel label={label} tooltipMessage={tooltipMessage} />
      <ComboBox
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        onChange={onSelectionChanged}
        value={value}
        metricName={metricName}
      />
    </DropdownLabelWrapper>
  );
};

NamedCombobox.propTypes = {
  label: PropTypes.string,
  tooltipMessage: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
  onSelectionChanged: PropTypes.func,
  value: PropTypes.string,
  metricName: PropTypes.string,
};

export default NamedCombobox;
