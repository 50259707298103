import { SLICE_KEYS, SLICE_NAMES } from './storeConstants';

const digitalTwinModelDataSelector = (state) => {
  return state[SLICE_NAMES.MASTER_SLICE][SLICE_KEYS.IS_MODEL_UPLOADED];
};

const digitalTwinConfigFlagSelector = (state) => {
  return state[SLICE_NAMES.MASTER_SLICE][SLICE_KEYS.IS_DT_ENABLED];
};

const getTagSourceListSelector = (state) => {
  let tagSourceList = [];
  const tagSettings =
    state[SLICE_NAMES.TAG_CONFIG_SLICE][SLICE_KEYS.TAG_SETTINGS_META];
  tagSettings?.tagSourceList?.forEach((setting) => {
    tagSourceList.push(setting.name);
  });
  return tagSourceList;
};

const getLinkDestinationListSelector = (state) => {
  let linkDestinationList = [];
  const tagSettings =
    state[SLICE_NAMES.TAG_CONFIG_SLICE][SLICE_KEYS.TAG_SETTINGS_META];
  tagSettings?.linkDestinationList?.forEach((setting) => {
    linkDestinationList.push(setting.name);
  });
  return linkDestinationList;
};

const getFunctionalAreaListSelector = (state) => {
  let functionalAreaList = [];
  const tagSettings =
    state[SLICE_NAMES.TAG_CONFIG_SLICE][SLICE_KEYS.TAG_SETTINGS_META];
  tagSettings?.functionalAreaList?.forEach((setting) => {
    functionalAreaList.push(setting.name);
  });
  return functionalAreaList;
};

export {
  digitalTwinConfigFlagSelector,
  digitalTwinModelDataSelector,
  getTagSourceListSelector,
  getLinkDestinationListSelector,
  getFunctionalAreaListSelector,
};
